.legacy-create-account {
  .wrapper {
    display: block;
    > * {
      max-width: 65%;
      @include phone {
        max-width: 100%;
      }
    }
  }
  h3 {
    margin-top: 30px;
  }
  .password-strength {
    @include desktop {
      margin-top: 15px;
      margin-bottom: 0;
    }
  }
  .question {
    padding-right: 30px;
  }
  .form-section {
    > .form-input {
      margin-left: 0;
      @include phone {
        max-width: 100%;
      }
    }
  }
  .paynow-dropdowns,
  .shipping-info {
    > .form-input {
      max-width: 100%;
    }
    select {
      max-width: 100%;
    }
  }
  .paynow-dropdowns {
    h3 {
      margin-bottom: 1em;
    }
  }
  .shipping-info {
    .side-by-side-select {
      .input-container {
        max-width: 100%;
      }
    }
  }
}

.shipping-info {
  @include phone {
    margin-bottom: 24px;
  }
}

.shipping-details-checkbox {
  margin-top: 20px;
  + h3 {
    margin-top: 50px;
  }
}

.image-with-testimonial {
  display: block;
  position: relative;
  picture {
    position: relative;
    z-index: 0;
  }
  blockquote {
    margin: 0;
    position: absolute;
    bottom: 20px;
    right: 20px;
    left: 20px;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    padding: 32px;
  }
  p {
    padding: 0;
    margin: 0 0 1em 0;
    color: #fff;
    line-height: 1.35;
  }
  cite {
    color: #fff;
    text-align: right;
    display: block;
    width: 100%;
  }
}

.testimonial-bubble {
  display: none;
  background: #e7eced;
  border-radius: 15px;
  align-items: center;
  color: #676969;
  padding: 36px 24px;
  margin: 30px 0 0 0;
  p {
    padding: 0;
    margin: 0 0 1em 0;
    font-size: 18px;
    line-height: 1.33;
  }
  @include phone {
    display: block;
  }
}

.email-instruction {
  padding: 12px;
  background: #f3f3f3;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  color: #6f6f6f;
  display: flex;
  margin-bottom: 20px;
  .email-typo-suggestion + & {
    margin-top: 10px;
  }
  a {
    color: $green;
    text-decoration: none;
    &:hover {
      color: $greenDark;
    }
  }
  > div > span {
    display: block;
    line-height: 1.4;
  }
  span + span {
    margin-top: 0;
  }
  p {
    margin: 6px 0 0;
  }
}

.i-icon {
  width: 16px;
  height: 16px;
  display: flex;
  flex-shrink: 0;
  margin-right: 8px;
  align-content: center;
  justify-content: center;
  border-radius: 50%;
  background: #767676;
  color: #fff;
  font-weight: bold;
  @include graphik-medium;
  font-size: 12px;
}

.account-credentials {
  &.page-2-columns .column:last-child {
    padding-top: 120px;
  }
}
