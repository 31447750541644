.selectors-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
  &.justify-left {
    justify-content: flex-start;
  }
  li {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 205px;
    @include phone {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
    + li {
      margin-left: 25px;
      @include phone {
        margin: 16px 0 0 0;
      }
    }
  }
  @include phone {
    flex-wrap: wrap;
  }
}

.selector-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #979797;
  color: #272727;
  background: none;
  cursor: pointer;
  overflow: hidden;
  padding: 0;
  position: relative;
  transition: all 0.12s ease-in-out;
  max-width: 100%;
  @include phone {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &.is-selected {
    background: $green;
    .star-comment {
      background-image: url(../media/icon-star-comment-white.svg);
    }
  }
  &:hover {
    border: 1px solid $green;
  }
  &.is-selected {
    color: #fff;
    background-color: $green;
    .checkmark {
      background-color: $green;
      opacity: 1;
    }
    .selector-title {
      background: none;
    }
  }
  .checkmark {
    width: 36px;
    height: 36px;
    display: block;
    border-radius: 50%;
    border: 2px solid #fff;
    background: #e7e7e7 url(../media/icon-checkmark-for-addiction-type.svg)
      center;
    background-size: auto 90%;
    position: absolute;
    left: 10px;
    top: 10px;
    @include phone {
      width: 25px;
      height: 25px;
      position: static;
      margin: auto 16px auto auto;
    }
  }
}

.selector-title {
  padding-left: 16px;
  padding-right: 16px;
  height: 64px;
  display: flex;
  flex-shrink: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 1.44;
  background-color: #f2f2f2;
  @include phone {
    justify-content: flex-start;
    text-align: left;
    background-color: #fff;
    max-width: calc(100% - 41px);
  }
}

.star-comment {
  background: url(../media/icon-star-comment.svg) left top no-repeat;
  width: 7px;
  height: 7px;
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
  transform: translateY(-100%);
}

.selector-image {
  max-width: 100%;
  @include phone {
    min-width: 25%;
    max-width: 25%;
    display: none;
  }
  img {
    width: 100%;
    min-height: 100%;
    height: auto;
    display: block;
  }
}

.selector-info {
  @include MN-Light;
  font-size: 14px;
  line-height: 1.44;
  color: $darkGray4;
  margin-top: 18px;
  @include phone {
    max-width: 100%;
  }
}

.form-footer {
  &.justify-left {
    text-align: left;
  }
}

.page-personalization {
  .form-input {
    max-width: 25%;
    margin: 0;
    @include phone {
      max-width: 100%;
    }
  }
}

.checklist,
.dosage-selectors-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.dosage-selectors-wrapper {
  .form-input {
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    .label-and-error {
      margin-left: 24px;
      margin-bottom: 0;
      order: 2;
      flex-wrap: wrap;
    }
    span {
      @include phone {
        display: none;
      }
    }
    + * {
      margin-top: 32px;
    }
  }
  input,
  select {
    width: 100%;
    max-width: 25%;
    @include phone {
      max-width: 100%;
    }
  }
  .input-error-message {
    min-width: 100%;
  }
}

.checklist {
  .checkbox-container {
    input[type='checkbox'] {
      + label {
        font-size: 16px;
        color: #28231e;
        line-height: 1;
        background-size: auto 16px;
      }
    }
    + * {
      margin-top: 24px;
    }
  }
  .user-free-text {
    margin-top: 10px;
  }
}

.user-free-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  .user-free-text-input {
    opacity: 0;
    margin-left: 8px;
    margin-top: 0;
    max-width: 500px;
  }
  .input-error-message {
    margin-left: 8px;
    position: absolute;
    transform: translate(0, 4px);
  }
  .form-input {
    max-width: 100%;
    margin-top: 0;
  }
  &.is-active {
    button {
      background-image: url(../media/icon-checkbox-checked.svg);
    }
    .user-free-text-input {
      opacity: 1;
    }
  }
}

.sensor-note {
  margin: 24px 0 0;
  display: inline-block;
  padding: 16px;
  border-radius: 8px;
  background: #f3f3f3;
  color: #696969;
  font-size: 14px;
  line-height: 20px;
  &.desktop {
    @include phone {
      display: none;
    }
  }
  &.mobile {
    margin-top: 16px;
    display: block;
    &::before {
      @include graphik-medium;
      content: 'i';
      padding: 5px;
      border-radius: 50%;
      border: 1px solid green;
      height: 18px;
      width: 18px;
      display: inline-block;
      box-sizing: border-box;
      font-size: 11px;
      color: green;
      line-height: 8px;
      text-align: center;
      margin-right: 8px;
    }
    @include desktop {
      display: none;
    }
  }
}
