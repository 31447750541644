@supports not (-ms-high-contrast: none) {
  .page-access-code {
    flex: 1;
    flex-direction: column;
    justify-content: stretch;
  }
}

.page-access-code {
  display: flex;
  min-height: 640px;
  min-height: 70vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 15%;
  overflow: hidden;
  @include phone {
    background-image: none !important;
  }
  @media (min-height: 1000px) {
    min-height: 640px;
  }
  .wrapper {
    margin: 0;
    padding: 0;
    max-width: 100%;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: stretch;
    position: relative;
    background-color: #f4f4f4;
    @include phone {
      flex-wrap: wrap;
    }
  }
  .copy-section {
    flex-basis: 50%;
    flex-shrink: 0;
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 80px 80px 80px 16px;
    @include phone {
      flex-basis: 100%;
      padding: 30px;
    }
    @include ipadMini {
      padding: 60px 40px 60px 60px;
    }
    h1 {
      @include phone {
        display: none;
      }
    }
  }
  .content {
    max-width: 600px;
    margin-top: auto;
    margin-bottom: auto;
    > * {
      @include phone {
        order: 10;
      }
    }
    @include phone {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      margin: auto;
    }
  }
  h1 {
    margin: 0;
    padding: 0;
    @include GR-Bold;
    font-size: 32px;
    color: $textColor;
    @include phone {
      font-size: 28px;
    }
  }
  p {
    @include MN-Book;
    font-size: 18px;
    line-height: 1.45;
    color: #333333;
    padding: 0;
    margin-top: 16px;
    margin-bottom: 0;
    max-width: 500px;
    + p {
      margin-top: 1em;
      margin-top: 1rem;
    }
    @include phone {
      text-align: center;
      margin-bottom: 0;
    }
  }
  .divider {
    min-width: 100%;
    border-top: solid 1px #979797;
    line-height: 1;
    margin: 25px 0;
    @include phone {
      order: 5;
      margin-bottom: 0;
    }
  }
  .icon-and-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 25px;
    @include phone {
      margin-top: 36px;
      padding-top: 36px;
      border-top: solid 1px #979797;
      flex-wrap: wrap;
    }
    + .icon-and-text {
      margin-top: 36px;
    }
    p {
      margin: 0;
      @include phone {
        margin: auto;
      }
    }
    img {
      margin-right: 18px;
      max-height: 45px;
      @include phone {
        margin: auto;
      }
    }
    a {
      color: $green;
    }
  }
  .access-code-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
    @include phone {
      order: 1;
      margin-top: 0;
    }
    @include ipadMiniPortrait {
      align-items: flex-start;
    }
    .next-frame-button {
      position: relative;
      top: 10px;
      @include phone {
        position: static;
        margin-top: 35px;
      }
    }
  }
  .form-input {
    flex: 1;
    margin: 0 36px 0 0;
    @include phone {
      order: 1;
      margin-right: 0;
      min-width: 100%;
      margin-bottom: 15px;
    }
    @include ipadMiniPortrait {
      max-width: 65%;
      min-width: 65%;
    }
  }
  .next-frame-button {
    @include phone {
      margin: auto;
      order: 3;
    }
    @include ipadMiniPortrait {
      order: 2;
      margin: 0;
    }
  }
  .support-copy,
  .learn-more-copy {
    margin-top: 8px;
    color: $darkGray4;
    font-size: 14px;
    min-width: 100%;
    a {
      color: $green;
    }
  }
  .support-copy {
    margin-top: 16px;
    @include phone {
      text-align: center;
      font-size: 12px;
      order: 2;
      margin-top: 0;
    }
    @include ipadMiniPortrait {
      text-align: left;
    }
  }
  .learn-more-copy {
    margin-top: 36px;
    @include phone {
      order: 2;
      margin-top: 16px;
      margin-bottom: 0;
    }
    @include ipadMiniPortrait {
      text-align: left;
    }
  }
}

.title-for-mobile {
  display: none;
  padding: 60px 0;
  text-align: center;
  width: 100%;
  @include phone {
    padding: 20px 10px;
    display: block;
  }
}

.hero-image {
  aspect-ratio: 1 / 0.45;
  width: 100%;
  height: 100%;
  img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    z-index: 0;
    @include phone {
      position: static;
      min-height: 250px;
    }
  }
}

.available-for-copy {
  font-size: 16px;
  color: #ffffff;
  background: $green;
  text-align: center;
  min-height: 40px;
  align-items: center;
  justify-content: center;
  transition: all 0.38s ease-in-out;
  left: 0;
  top: 0;
  right: 0;
  z-index: 100;
  position: absolute;
  display: flex;
  margin-top: -100px;
  &.is-active {
    top: 0;
    margin-top: 0;
    + .image-section {
      > h1 {
        padding-top: 50px;
      }
    }
  }
  @include phone {
    position: relative;
    text-align: center;
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
    flex: 1;
  }
  span {
    margin-left: auto;
    margin-right: 0;
    flex: 1;
    text-align: center;
  }
  button {
    justify-self: flex-end;
    margin-left: auto;
    margin-right: 15px;
    display: block;
    width: 14px;
    height: 14px;
    border: none;
    cursor: pointer;
    background: transparent url(../media/icon-exit-white.svg) center center
      no-repeat;
  }
}

.support-chat-launcher {
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  border: none;
  text-decoration: underline;
  color: $green;
  cursor: pointer;
  background: none;
}

.content > .get-started-btn {
  margin-top: 25px;
}

@keyframes slideUp {
  0% {
    transform: translate(0, 68px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.social-proof {
  $delay: 0.24s;
  background: #f4f4f4;
  .wrapper {
    margin-top: -34px;
    padding-top: 0;
    padding-bottom: 40px;
    display: flex;
    justify-content: space-between;
    max-width: 1300px;
    @include phone {
      flex-direction: column;
      padding: 25px 15px;
      margin: 0;
    }
    > div {
      width: 380px;
      min-height: 300px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 4px 8px 6px rgba(0, 0, 0, 0.03);
      padding: 42px 30px 56px 30px;
      margin: 0 25px;
      @include desktop {
        transform: translate(0, 34px);
        animation: slideUp 0.8s ease-in forwards;
      }
      @include phone {
        margin: 15px 0;
        width: 100%;
      }
      &:nth-child(1) {
        animation-delay: 0s;
      }
      &:nth-child(2) {
        animation-delay: calc(#{$delay} * 1);
      }
      &:nth-child(3) {
        animation-delay: calc(#{$delay} * 2);
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    h2 {
      @include graphik-medium;
      font-size: 23px;
      color: #333333;
      margin: 0 0 20px 0;
    }
    p {
      @include graphik-regular;
      font-size: 18px;
      line-height: 1.44;
      color: #464646;
      margin: 0;
      padding: 0;
    }
  }
  .program-disclaimer {
    text-align: left;
    margin-top: 0;
    padding: 0 60px 40px 60px;
    @include phone {
      margin: -15px 0 0 0;
      padding: 0 20px 30px 20px;
      font-size: 14px;
      text-align: center;
    }
    h3 {
      @include graphik-medium;
      font-size: 23px;
      line-height: 1;
      color: $textColor;
      margin: 0 0 10px 0;
      padding: 0;
      @include phone {
        font-size: 20px;
      }
    }
    button {
      color: darken($green, 2%);
      padding: 0;
      margin: 0;
      font-size: inherit;
      line-height: inherit;
      background: none;
      border: none;
      cursor: pointer;
      text-decoration: underline;
      display: contents;
    }
  }
}

.testimonial-and-stats {
  display: flex;
  flex-direction: column;
  blockquote {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    color: #696969;
    @include graphik-regular;
    font-size: 18px;
    line-height: 1.44;
  }
  cite {
    display: block;
    font-style: normal;
    font-size: 14px;
    margin-top: 12px;
  }
}

.stats-counter {
  @include graphik-medium;
  font-size: 48px;
  color: $green;
  position: relative;
  display: block;
  margin-bottom: 12px;
}

.hero-copy-left {
  .copy-section {
    order: 1;
    @include phone {
      order: 3;
    }
  }
  .image-section {
    order: 2;
  }
}

.dark-bkg {
  .copy-section {
    h1 {
      @include graphik-regular;
      color: #fff;
      font-size: 32px;
      line-height: 1.5;
    }
    p {
      color: #fff;
      @include phone {
        color: $darkGray4;
      }
    }
    .divider {
      border-top-color: #fff;
      margin: 45px 0;
      @include phone {
        display: none;
      }
    }
    .get-started-btn {
      margin-top: 0;
    }
  }
}

.testimonial-video {
  background: #f4f4f4;
  .wrapper {
    max-width: $dtcMaxWidth;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include phone {
      flex-direction: column;
      padding: 45px 30px;
    }
  }
  .image-wrapper {
    flex: 1;
    flex-shrink: 0;
    flex-basis: 400px;
    max-width: 400px;
    border-radius: 1px;
    overflow: hidden;
    @include phone {
      flex-basis: auto;
      margin-bottom: 30px;
    }
    img {
      max-width: 100%;
      height: auto;
      display: block;
      cursor: pointer;
    }
  }
  .content {
    padding-left: 100px;
    @include phone {
      padding-left: 0;
    }
  }
  h2 {
    @include graphik-medium;
    font-size: 32px;
    margin: 0 0 12px 0;
    padding: 0;
    @include phone {
      font-size: 20px;
    }
  }
  p {
    @include graphik-regular;
    font-size: 18px;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    @include phone {
      font-size: 14px;
    }
  }
}

.play-video-btn {
  @include graphik-medium;
  font-size: 20px;
  line-height: 1.2;
  cursor: pointer;
  color: #cc0000;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin-top: 40px;
  @include phone {
    margin-top: 20px;
  }
  svg {
    margin-right: 20px;
    @include phone {
      margin-right: 12px;
      width: 42px;
      height: 42px;
    }
  }
  span {
    text-decoration: underline;
  }
  &:hover {
    color: #da0000;
    background: none;
    border-color: #da0000;
    path {
      fill: #da0000;
    }
  }
}

.video-container {
  order: 1;
  max-width: 700px;
  margin: auto auto 44px auto;
  @include phone {
    width: 100%;
  }
}

.youtube-player {
  text-align: left;
  height: 0;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
