$small-phone-width: 340px;
$phone-width: 991px;

@mixin small-phone {
  @media (max-width: #{$small-phone-width}) {
    @content;
  }
}

@mixin phone {
  @media (max-width: #{$phone-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$phone-width}) {
    @content;
  }
}

@mixin landscape {
  @media only screen and (orientation: landscape) {
    @content;
  }
}

@mixin portrait {
  @media only screen and (orientation: portrait) {
    @content;
  }
}

@mixin ipadMini {
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}

@mixin ipadMiniPortrait {
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}

@mixin iPadPortrait {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}

@mixin iPadProPortrait {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin desktop4K {
  @media screen and (min-height: 1000px) {
    @content;
  }
}
