input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid $inputBorder;
  -webkit-text-fill-color: $textColor;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  transition: background-color 5000s ease-in-out 0s;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

legend {
  padding-left: 0;
  padding-right: 0;
}

.form-input {
  margin: 0 auto 16px auto;
  display: block;
  &.email-input {
    position: relative;
  }
  input {
    width: 100%;
    @include MN-Book;
    font-size: 16px;
    color: $textColor;
    border: 1px solid $inputBorder;
    border-radius: 4px;
    padding: 12px 12px;
    transition: all 0.12s ease-in-out;
    &:disabled {
      background: #efefef;
      cursor: not-allowed;
    }
  }
  select {
    width: 100%;
    font-size: 16px;
    color: $textColor;
    appearance: none;
    border: 1px solid $inputBorder;
    border-radius: 4px;
    padding: 12px 12px;
    background: #fff url(../media/arrow-dropdown.svg) calc(100% - 12px) center
      no-repeat;
    background-size: auto 10px;
    &:focus,
    &:active {
      color: $textColor;
    }
  }
  label {
    @extend .label;
  }
  ::placeholder {
    color: rgba($textColor, 0.5);
    @include MN-Book;
  }
}

.required {
  background: url(../media/icon-required.svg) left top no-repeat;
  width: 7px;
  height: 7px;
  display: inline-block;
  margin-left: 4px;
}

.is-valid-null,
.is-pristine,
.is-default-value {
  select,
  select:focus,
  select:active {
    color: rgba($textColor, 0.5);
  }
}

.input-container {
  position: relative;
}

.label-and-error {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 4px;
}

.label {
  color: $textColor;
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;
  display: block;
  @include graphik-medium;
}

.spinner {
  position: absolute;
  right: 5px;
  transform-origin: center;
  transform: scale(0.8);
  opacity: 1;
  transition: all 0.12s ease-in-out;
}

.is-valid-true {
  select {
    color: $textColor;
    border-left: 2px solid $green;
  }
  input[type='password'],
  input[type='number'],
  input[type='date'] {
    border-left: 2px solid $green;
  }
}

.is-touched {
  &.is-valid {
    select,
    input {
      border-left: 2px solid $green;
    }
    .input-message {
      color: $green;
    }
  }

  &.has-error {
    input,
    select {
      border-color: $errorRed;
    }
  }
}

.side-by-side-inputs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  > * {
    flex-shrink: 0;
    margin: 0;
    width: 100%;
    max-width: calc(50% - 10px);
    @include phone {
      min-width: 100%;
    }
  }
  > *:first-child {
    @include phone {
      margin-bottom: 30px;
    }
  }
  &.mobile-side-by-side {
    > * {
      max-width: calc(50% - 10px);
      min-width: initial;
      margin-bottom: 0;
      @include phone {
        max-width: calc(50% - 8px);
      }
    }
  }
}

.stacked-password-comparison-with-info {
  .form-input:last-of-type {
    margin-bottom: 0;
  }
}

.input-error-message,
.input-message {
  display: block;
  color: $errorRed;
  font-size: 12px;
  line-height: 1;
  margin-left: auto;
  padding-left: 10px;
  &.for-combined-password-field {
    max-width: 100%;
    text-align: left;
  }
}

.access-code-input {
  .input-message {
    max-width: 100%;
    position: static;
  }
  > :first-child {
    @include phone {
      margin-bottom: 0;
    }
  }
}

.sms-additional-options {
  margin-left: 20px;
}

.field-instructions {
  @include MN-Book;
  display: block;
  min-width: 100%;
  width: 100%;
  font-size: 14px;
  line-height: 1.35;
  color: #979797;
  margin-top: 6px;
  text-align: left;
}

.launch-support-widget-button {
  background: none;
  border: none;
  color: $green;
  margin: 0;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
}

.form-footer {
  text-align: center;
  margin-top: 40px;
  @include phone {
    margin-top: 24px;
  }
  * + * {
    margin-left: 24px;
    @include phone {
      margin-left: 0;
      margin-top: 24px;
    }
  }
}

.pin-to-bottom {
  @include phone {
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 24px 15px;
    margin: 0 -15px;
    background: #fff;
  }
}

.is-pinned {
  background: #fff;
  &:before {
    @include phone {
      content: '';
      display: block;
      height: 15px;
      min-width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 0;
      background: #fff;
      transition: all 0.12s;
      box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.08);
    }
  }
}

.bottom-pin-ref {
  top: -1px;
}

.pivot-datepicker-container {
  .label {
    font-size: 18px;
    margin-bottom: 16px;
  }
}

.pivot-datepicker {
  display: flex;
  justify-content: space-between;
  @include phone {
    flex-flow: wrap;
  }
  label {
    display: flex;
    align-items: flex-start;
    margin-bottom: 4px;
  }
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  select {
    padding-right: 16px;
    background-position-x: calc(100% - 10px);
  }
  > *:first-child {
    flex: 1;
    min-width: 130px;
    @include phone {
      width: 55%;
      max-width: 55%;
    }
  }
  > *:nth-child(2) {
    margin: 0 20px;
    max-width: 95px;
    min-width: 80px;
    @include phone {
      width: calc(45% - 16px);
      max-width: calc(45% - 16px);
      margin: 0 0 0 16px;
    }
  }
  > *:last-child {
    min-width: 150px;
    @include phone {
      min-width: auto;
      width: 100%;
      margin-top: 15px;
    }
  }
}

.is-valid-false {
  .pivot-datepicker {
    input,
    select {
      border-color: $errorRed;
    }
  }
}

.is-valid {
  .pivot-datepicker {
    select {
      color: $textColor;
      border-left: 2px solid $green;
    }
    input[type='number'] {
      border-left: 2px solid $green;
    }
  }
}

.checkbox-container {
  position: relative;
  input[type='checkbox'] {
    position: absolute;
    left: 0;
    top: 0;
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
    opacity: 0;
  }
  input[type='checkbox'] + label {
    @include MN-Book;
    color: #666666;
    font-size: 12px;
    line-height: 20px;
    padding-left: 30px;
    margin-bottom: 0;
    background-image: url(../media/icon-checkbox-checked.svg); //for preloading purposes
    background: url(../media/icon-checkbox-unchecked.svg) left top no-repeat;
    background-size: auto 18px;
    min-height: 20px;
    position: relative;
    white-space: unset;
    position: relative;
    &:hover {
      cursor: pointer;
    }
  }
  input[type='checkbox']:checked + label {
    background-image: url(../media/icon-checkbox-checked.svg);
  }

  input[type='checkbox']:focus + label {
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      background: $greenDark;
      opacity: 0.2;
      box-shadow: 0 0 8px 0 $greenDark;
      border-radius: 2px;
      pointer-events: none;
    }
  }
}

.account-phone-number {
  .has-checkbox {
    display: flex;
    align-items: center;
  }
}

.email-input {
  margin-bottom: 0;
}

.email-typo-suggestion {
  font-size: 12px;
  text-align: right;
  padding-top: 4px;
  min-height: 20px;
  .page-partner-email & {
    max-width: 25%;
  }
  .mini-form-container & {
    margin-top: 0;
    margin-bottom: 8px;
    transform: translateY(-12px);
  }
  .page-create-account-dtc & {
    max-width: calc(50% - 15px);
    margin-top: 0;
  }
  @include phone {
    .page-2-columns & {
      margin-bottom: 12px;
    }
    .page-partner-email & {
      max-width: 100%;
    }
    .mini-form-container & {
      margin-bottom: 16px;
      transform: translateY(0);
    }
    .page-create-account-dtc & {
      max-width: none;
    }
  }
  a,
  button {
    border: none;
    background: none;
    line-height: 1;
    padding: 0;
    margin: 0;
    color: $green;
    text-decoration: underline;
    cursor: pointer;
  }
}
