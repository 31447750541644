.account-creation {
  text-align: left;
  margin-left: auto;
  margin-right: auto;

  .form-intro {
    margin: 0 0 55px 0;
    max-width: 950px;
    font-size: 18px;
    line-height: 1.4;
    color: $darkGray4;
    @include MN-Book;
    @include phone {
      font-size: 18px;
    }
  }

  .form-section {
    max-width: 620px;
    + .form-section {
      margin-top: 85px;
    }
  }
  h3 {
    @include G-Bold;
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 0.5em;
    display: inline-block;
  }
  h4 {
    font-size: 26px;
    margin-top: 0;
    margin-bottom: 0.5em;
  }
}

.form-instructions {
  margin-top: 0;
  margin-bottom: 15px;
  @include MN-Book;
  color: $textColor;
  font-size: 14px;
}
