.password-strength-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 25px;
  position: relative;
  > label:first-child {
    margin-bottom: 5px;
    width: 100%;
    @include phone {
      margin-bottom: 5px;
    }
  }
  .label {
    margin-bottom: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.password-strength {
  width: 100%;
  order: 3;
  margin: 0;
  font-size: 14px;
  position: static;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  .criteria {
    display: block;
    padding-left: 20px;
    color: #4a4a4a;
    line-height: 1;
    background-image: url(../media/Password_incomplete.svg);
    background-repeat: no-repeat;
    background-position: left center;
    transition: all 0.12s ease-in-out;
    margin: 0 16px 0 0;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .criteria-wrong {
    color: #b73b39;
    background-image: url(../media/Password_incorrect.svg);
  }
  .criteria-ok {
    color: darken($green, 2%);
    background-image: url(../media/Password_correct.svg);
  }
}

.password-toggle-type-button {
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 28px;
  width: 25px;
  height: 21px;
  margin: 0;
  padding: 0;
  border: none;
  @include GR-Book;
  font-size: 14px;
  color: #979797;
  background: transparent url(../media/Password_Show.svg) left center no-repeat;
  &.is-type-text {
    background: transparent url(../media/Password_Hide.svg) left center
      no-repeat;
  }
}
