$tooltipSize: 16px;

.cs-tooltip {
  display: inline-block;
  position: relative;
  z-index: 10;
  width: $tooltipSize;
  height: $tooltipSize;
  margin: 0;
  &.cs-tooltip-showing {
    .tooltip-trigger:before {
      display: block;
    }
    .tooltip-content {
      display: block;
    }
  }
}

.tooltip-trigger {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $tooltipSize;
  height: $tooltipSize;
  border-radius: 50%;
  background: $green;
  cursor: help;
  transition: all 0.12s;
  path {
    fill: #fff;
  }
  &:hover {
    box-shadow: 0 0 7px rgba($green, 0.25);
  }
  &:before {
    content: '';
    display: none;
    position: absolute;
    z-index: 15;
    right: 0;
    top: 0;
    transform: translate(20px, 0);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #efefef;
    @include phone {
      border-top: 10px solid transparent;
      border-bottom: 10px solid #efefef;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      right: auto;
      left: 0;
      top: 80%;
      transform: translate(-1px, 0);
    }
  }
}

.tooltip-content {
  display: none;
  position: absolute;
  z-index: 10;
  left: 100%;
  top: 0;
  min-width: 350px;
  max-width: 450px;
  padding: 8px;
  border-radius: 8px;
  background-color: #efefef;
  font-size: 14px;
  line-height: 1.43;
  color: rgba(74, 74, 74, 0.8);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  transform: translate(16px, -25%);
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.25);
  @include phone {
    top: 80%;
    left: 0;
    right: auto;
    min-width: 275px;
    transform: translate(-25%, 27px);
  }
}

.mobile-open-direction-left {
  .tooltip-content {
    @include phone {
      transform: translate(-90%, 27px);
    }
  }
}
