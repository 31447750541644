.obstacle-modal {
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(40, 35, 30, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  @include phone {
    padding: 15px;
  }
}

.close-modal-button {
  display: block;
  width: 20px;
  height: 20px;
  background: transparent url(../media/icon-exit.svg) center center no-repeat;
  position: absolute;
  right: 20px;
  top: 20px;
  border: none;
  opacity: 1;
  cursor: pointer;
  transition: opacity 0.12s linear;
  &:hover {
    opacity: 0.5;
  }
  @include phone {
    right: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
    background-size: 20px auto;
  }
}

.inner-modal {
  position: relative;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.32);
  padding: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  max-width: 950px;
  align-items: flex-start;
  h3 {
    font-weight: 400;
  }
  @include phone {
    padding: 40px;
  }
  h3 {
    margin: 0 0 1em 0;
    padding: 0;
    @include G-Bold;
    font-size: 22px;
    text-align: left;
    width: 100%;
    font-weight: 400;
    @include phone {
      font-size: 20px;
    }
  }
  p {
    max-width: 100%;
    margin: 0 0 1em 0;
    padding: 0;
    font-size: 22px;
    line-height: 1.35;
    color: #444444;
    @include phone {
      font-size: 16px;
    }
  }
  .exit-button {
    margin-top: 50px;
    margin-bottom: 15px;
  }
  .change-answer-button {
    border: none;
    box-shadow: none;
    background: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .form-footer {
    margin-top: 35px;
  }
  .scrollable {
    max-height: 75vh;
    overflow: auto;
    scroll-behavior: smooth;
  }
}

.input-with-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin: 0;
  input[type='email'] {
    width: 100%;
    @include MN-Book;
    font-size: 18px;
    color: #979797;
    border: 1px solid $inputBorder;
    border-right: none;
    border-radius: 4px 0 0 4px;
    padding: 15px 20px;
    transition: all 0.12s ease-in-out;
  }
  ::placeholder {
    @include MN-Book;
    color: rgba(#979797, 0.5);
  }
}

.shortlist-submit-button {
  @include GR-Book;
  font-size: 18px;
  color: $green;
  border: 1px solid $green;
  background: #fff;
  border-radius: 0 4px 4px 0;
  padding: 15px 20px;
  transition: all 0.12s ease-in-out;
  cursor: pointer;
  &:hover {
    background: $green;
    color: #fff;
    box-shadow: none;
    border: 1px solid $green;
  }
  &.is-sending {
    padding-left: 42px;
  }
}

.ra-modal {
  .inner-modal {
    max-width: 870px;
    align-items: flex-start;
    font-size: 18px;
    line-height: 1.4;
    @include phone {
      padding-left: 30px;
      padding-right: 30px;
      font-size: 14px;
    }
    > *:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
    h3 {
      font-size: 18px;
      font-weight: 400;
      color: $green;
      @include phone {
        font-size: 14px;
      }
    }
    p,
    ul,
    li {
      font-size: inherit;
    }
    ul {
      padding: 0 0 0 1em;
      margin-bottom: 2em;
      @include phone {
        padding-left: 0;
        list-style: none;
      }
    }
    li {
      margin: 0;
      padding: 0;
    }
    strong {
      @include graphik-medium;
      font-weight: normal;
    }
  }
}

.video-modal {
  .inner-modal {
    background: none;
    padding: 0;
    width: 100%;
    display: block;
    box-shadow: none;
  }
  .close-modal-button {
    right: -10px;
    top: -10px;
    transform: translate(100%, -100%);
    background-image: url(../media/icon-exit-white.svg);
    @include phone {
      top: -10px;
      transform: translate(0, -100%);
    }
  }
  .video-container {
    max-width: none;
    margin: 0 auto;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.32);
  }
  .youtube-player {
    background: #000000;
  }
  .spinner {
    right: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.unsupported-type-modal {
  .inner-modal {
    .form-footer {
      @include phone {
        text-align: center;
      }
    }
  }
}

.access-code-not-started-modal {
  p:last-child {
    margin-bottom: 0;
  }
  a {
    color: $green;
  }
  .inner-modal {
    .form-footer {
      @include phone {
        text-align: center;
      }
    }
  }
}

.vp-modal,
.ph-modal {
  .inner-modal {
    align-items: center;
    text-align: center;
    max-width: 480px;
    padding: 25px;
  }
  h3 {
    margin-top: 25px;
    text-align: center;
    @include phone {
      @include graphik-regular;
    }
  }
  p {
    font-size: 18px;
    @include phone {
      font-size: 14px;
    }
  }
  a {
    color: $green;
  }
  .form-footer {
    margin-top: 0;
  }
  button {
    border-radius: 8px;
    @include phone {
      font-size: 20px;
    }
  }
  a.cs-button {
    @extend .cs-button;
    border-radius: 8px;
    @include phone {
      font-size: 20px;
    }
  }
}

.session-timeout-modal {
  .inner-modal {
    max-width: 490px;
  }
}

.cookies-modal {
  .inner-modal {
    max-width: 590px;
  }
  .form-footer {
    @include phone {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .support-chat-launcher {
    @extend .cs-button;
    @extend .cs-button-borderless;
    border: 1px solid $green;
    border-radius: 8px;
    @include phone {
      font-size: 20px;
      margin-top: 20px;
    }
  }
}

.message-modal {
  .message2-text {
    margin-top: 20px;
  }
  .image {
    max-width: 400px;
    margin: 0 auto;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .cs-button {
    margin: 20px auto 0;
  }
}
