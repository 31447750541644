*:focus {
  outline: none;
}

input[type='checkbox']:focus + label,
a,
label,
input,
button,
textarea,
select {
  &:focus {
    outline: 2px solid $green;
  }
}

.screen-reader-text {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  &.page-title {
    width: 100%;
    height: 0;
  }
}
