* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  @include GR-Book;
  color: $textColor;
  background: #fff;
  &.navIsOpen {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

br {
  font-family: Arial, sans-serif;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.brand-bar {
  position: relative;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 28px 0;
  &.nrt-header {
    justify-content: flex-start;
    padding: 28px;
    img {
      @include phone {
        height: 20px;
      }
    }
  }
  @include phone {
    padding: 16px 20px;
  }
  .wrapper {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 2000px;
    margin: 0 auto;
    padding: 0 28px;
    @include phone {
      padding: 0;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

.brand-bar-copy {
  @include MN-Light;
  font-size: 26px;
  color: $textColor;
  margin-left: 60px;
  @include phone {
    margin: 15px auto auto 0;
    font-size: 14px;
  }
}

.header-logo {
  z-index: 100;
  @include phone {
    height: 28px;
    max-width: 60%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  img {
    height: 34px;
    width: auto;
    display: block;
    @include phone {
      min-width: 18vw;
      max-width: 100%;
      max-height: unquote('max(34px, 100%)');
      height: auto;
    }
  }
}

.partner-plus {
  height: 12px;
  display: block;
  margin: auto 16px;
}

.partner-logo {
  height: 34px;
  width: auto;
  display: block;
}

.login-button {
  color: $targetRed;
  margin: auto 0 auto auto;
  padding: 12px 0 !important;
  border: 1px solid transparent;
  font-size: 16px !important;
  line-height: 1;
  background: none;
  cursor: pointer;
  white-space: nowrap;
}

footer {
  position: relative;
  background: #f9f9f9;
  padding: 20px 36px;
  margin: auto 0 0 0;
  @include phone {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  nav {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    @include phone {
      flex-wrap: wrap;
      text-align: center;
    }
  }
}

.footer-logo {
  width: 46px;
  height: 46px;
  display: inline-block;
  overflow: hidden;
  @include phone {
    margin: 15px auto;
  }
}

.footer-link,
.copyright {
  color: $footerLinks;
  font-size: 16px;
  @include MN-Medium;
  margin-left: 45px;
  text-decoration: none;
  border: none;
  background: none;
  cursor: pointer;
  &.selected {
    background: grey;
    color: #fff;
  }
  @include phone {
    min-width: 100%;
    margin: 15px 0;
    display: block;
    font-size: 14px;
  }
}

.server-message {
  position: fixed;
  transition: all 0.26s ease-in-out;
  z-index: 250;
  left: 0;
  top: 0;
  right: 0;
  padding: 15px;
  color: #fff;
  @include GR-Book;
  font-size: 16px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  opacity: 0;
  pointer-events: none;
  a {
    color: #fff;
  }
  &.type-warn {
    opacity: 1;
    background: $orange;
    pointer-events: auto;
  }
  &.type-success {
    opacity: 1;
    background: $green;
    pointer-events: auto;
  }
  &.type-error {
    opacity: 1;
    background: $errorRed;
    pointer-events: auto;
  }
  &.type-info {
    opacity: 1;
    background: $yellow;
    color: $textColor;
    pointer-events: auto;
    a {
      color: $textColor;
    }
  }
}

.suspense-preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  .spinner {
    position: static;
    transform: translate(0, 0);
  }
}

// An embedded third party script which causes height/space issue under the page
#root ~ iframe[src='javascript:false'] {
  display: none;
}

.hide-mobile {
  @include phone {
    display: none !important;
  }
}

.hide-desktop {
  @include desktop {
    display: none !important;
  }
}

#helpscout-facade {
  position: absolute;
  z-index: 1000;
  bottom: 15px;
  right: 20px;
  transition: all 200ms linear 0s;
  @include phone {
    display: none;
  }
  &.show-on-mobile {
    @include phone {
      display: unset;
    }
  }
  button {
    appearance: none;
    border-radius: 200px;
    justify-content: center;
    margin: 0;
    outline: none;
    user-select: none;
    z-index: 999;
    background-color: $green;
    border: none;
    color: white;
    cursor: pointer;
    min-width: 60px;
    -webkit-tap-highlight-color: transparent;
    height: 55px;
    line-height: 55px;
    padding: 0 0 0 0;
    display: flex;
    align-items: center;
    &:focus,
    &:hover {
      background-color: $greenDark;
    }
    .icon {
      width: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    span {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
