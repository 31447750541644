$leftColWidth: 520px;
$rightColWidth: 480px;

.page-eligibility-intro {
  .step-copy {
    font-size: 24px;
    color: #000;
  }
  small {
    font-size: 18px;
    color: $textColor;
  }
  .next-frame-button {
    min-width: 190px;
  }
}

.circular-image {
  margin: 100px auto auto auto;
  width: 330px;
  height: 330px;
  border-radius: 50%;
  overflow: hidden;
  border: 7px solid #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  object-fit: cover;
  img {
    width: 100%;
    height: 100%;
  }
}

.page-eligibility-intro,
.page-eligibility,
.page-eligibility-approved {
  .wrapper {
    display: flex;
    justify-content: space-between;
    @include phone {
      justify-content: center;
    }
  }
  .column {
    @include phone {
      width: 100%;
    }
    &:first-child {
      max-width: $leftColWidth;
    }
    &:last-child {
      max-width: $rightColWidth;
      padding: 120px 20px 20px;
    }
    img {
      max-width: 100%;
    }
  }
  .input-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include phone {
      flex-wrap: wrap;
    }
  }
  .form-input {
    flex: 1;
    @include phone {
      width: 100%;
      margin-right: 0;
    }
  }
  .form-input-instruction {
    font-size: 14px;
    opacity: 0.5;
    @include phone {
      order: 1;
      margin-bottom: 5px;
    }
  }
  .form-footer {
    text-align: left;
    margin-top: 20px;
  }
  .is-hidden {
    display: none;
  }
  .top-image-mobile {
    max-width: 150px;
    margin-bottom: 24px;
  }
}

.support-boxes {
  display: flex;
  margin-top: 36px;
  @include phone {
    flex-wrap: wrap;
  }
  &.justify-left {
    justify-content: flex-start;
  }
}

.support-box {
  background: #f8f8f8;
  border: 1px solid #c1c1c1;
  padding: 24px;
  font-size: 14px;
  line-height: 20px;
  @include phone {
    margin: 0;
    height: auto;
  }
  &:first-child {
    margin-left: 0;
    @include phone {
      margin-bottom: 18px;
    }
  }
  &:last-child {
    margin-right: 0;
  }
  h3 {
    @include MN-Bold;
    margin: 0;
    padding: 0;
    font-size: 18px;
  }
  a {
    color: #017d6d;
    text-decoration: none;
  }
  .cs-button {
    margin-top: 30px;
    font-size: 18px;
  }
  hr {
    margin: 2.5em 0;
  }
}

.primary-holder-subtitle {
  font-size: 24px;
  margin-top: 30px;
}

.privacy-policy-btn {
  background: none;
  border: none;
  color: $green;
  padding: 0;
  margin: 0;
  @include graphik-medium;
  font-size: 16px;
  cursor: pointer;
}

.memberid-field-with-tooltip {
  position: relative;
  .cs-tooltip {
    position: absolute;
    top: -2px;
    left: 120px;
  }
}

.page-eligibility-intro {
  .wrapper {
    display: block;
  }
  .step-copy {
    @include phone {
      font-size: 17px;
    }
    small {
      @include phone {
        margin-top: 1em;
        display: block;
      }
    }
  }
  .circular-image {
    @include phone {
      width: 200px;
      height: 200px;
      margin: 0 auto 30px auto;
      flex-shrink: 0;
    }
  }
  .form-footer {
    margin-top: 50px;
    text-align: center;
  }
}

.page-eligibility-approved {
  .step-header {
    float: left;
    @include phone {
      float: none;
    }
  }
  .section-instructions {
    clear: left;
    color: $textColor;
  }
  .form-footer {
    margin-top: 30px;
    text-align: left;
  }
  .column {
    &:last-child {
      max-width: 620px;
      padding: 20px;
    }
  }
  .content {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      counter-increment: elig-approved-counter;
      margin-bottom: 16px;
      &::before {
        @include graphik-medium;
        font-size: 16px;
        content: counter(elig-approved-counter);
        border: 2px solid $textColor;
        border-radius: 50%;
        display: inline-block;
        width: 26px;
        height: 26px;
        text-align: center;
        line-height: 26px;
        margin-right: 8px;
        transform: translateY(-2px);
      }
    }
  }
  .content-title {
    @include graphik-medium;
    font-size: 23px;
    line-height: 30px;
    display: inline-block;
  }
  .content-copy {
    color: #696969;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
  }
}

.cdphp-elig-message {
  color: white;
  background: $errorRed;
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 14px;
  line-height: 18px;

  a {
    color: white;
  }
}

.dont-know-id-wrap {
  text-align: right;
  padding: 16px 0;
}

.dont-know-id {
  color: #017d6d;
  font-size: 15px;
  @include graphik-medium;
}
