.email-verification {
  img {
    height: 274px;
    width: auto;
    margin-bottom: 30px;
    @include phone {
      height: 180px;
    }
  }
}
