@import 'utils';
@import 'font-mixins';
@import 'vars';

.page-download-app {
  counter-reset: download-app;
  &.centralize {
    text-align: center;
    .section-title {
      margin-top: 30px;
      font-size: 40px;
      line-height: 42px;
    }
    * {
      text-align: center;
    }
    ol {
      display: inline-block;
    }
  }
  .column:first-child {
    width: 100%;
    @include phone {
      max-width: 100%;
    }
  }
  .column:last-child {
    justify-content: flex-end;
  }
  .section-title {
    margin-bottom: 24px;
  }
  .section-instructions {
    font-size: 18px;
    color: $textColor;
    @include phone {
      font-size: 15px;
    }
    &.small {
      font-size: 15px;
    }
    b {
      @include graphik-medium;
    }
    &.small {
      font-size: 15px;
    }
    a {
      text-decoration: none;
    }
  }
  .app-stores {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    width: 250px;
    img {
      width: 150px;
      height: auto;
      @include phone {
        width: 209px;
      }
    }
  }
  .column:last-child {
    img {
      height: auto;
      object-fit: cover;
    }
  }
  ol {
    padding: 0;
    margin: 0 0 20px;
  }
  li {
    margin: 0;
    display: inline-block;
    &::before {
      counter-increment: download-app;
      content: counter(download-app) '. ';
    }
  }
  .qr-code {
    width: 88px;
  }

  .next-frame-button {
    display: block;
    margin: 16px 0;
    font-size: 17px;
  }
}

.verify-email-notification {
  margin: 45px 0;
  font-size: 18px;
  line-height: 1.5;
  word-break: break-word;
  padding: 20px 30px;
  background: #e7eced;
  border-radius: 4px;
  color: #3e3935;
  a {
    color: $green;
  }
}

.resend-confirmation-link {
  background: none;
  border: none;
  color: $green;
  text-decoration: underline;
  display: contents;
  font-size: inherit;
  cursor: pointer;
}

.appstore-links-description {
  padding: 20px 16px;
  background: #f2fbf9;
  border-radius: 4px;
  color: #3e3935;
  margin-bottom: 1em;
  .section-instructions {
    font-size: 14px;
  }
  .links {
    margin: 20px 0;
    display: flex;
    gap: 20px;
    picture img {
      width: 58px;
    }
    > div {
      flex: 1;
      text-align: center;
      .section-instructions,
      .app-stores {
        text-align: center;
      }
    }
  }
}

.app-link-button {
  color: white;
  background: $greenDark;
  font-size: 20px;
  @include graphik-medium;
  display: block;
  width: 100%;
  padding: 15px;
  border-radius: 16px;
  text-decoration: none;
}

.page-email-verified {
  .wrapper {
    position: static;
    @include clearfix;
  }
  .step-header,
  .section-instructions {
    max-width: 450px;
    display: inline-block;
  }
  .open-pivot-app {
    margin-top: 100px;
  }
  .appstore-links-description {
    margin-top: 1em;
  }
}

.image-right {
  float: right;
  @include phone {
    float: none;
    margin-bottom: 45px;
    text-align: center;
  }
  img {
    @include phone {
      max-height: 260px;
      width: auto;
      max-width: 100%;
    }
  }
}

.webui-login-page {
  .column {
    width: 100%;
    @include phone {
      max-width: 100% !important;
    }
  }
  .column:first-child {
    max-width: 46%;
    margin-right: 0;
    padding-right: 0;
  }
  .column:last-child {
    max-width: 52%;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.webui-title {
  line-height: 1.1;
  strong {
    display: block;
    margin-top: 24px;
  }
}

.webui-cta {
  margin: 24px 0;
}
