$inputWidth: 45%;

.page-find-employer {
  .form-input {
    position: relative;
    width: $inputWidth;
    margin: 0 24px 0 0;
    @include phone {
      width: 100%;
    }
  }
  .label {
    @include phone {
      white-space: break-spaces;
    }
  }
  .input-container + * {
    margin-top: 20px;
  }
  .side-by-side-inputs {
    justify-content: flex-start;
    align-items: center;
    > * {
      max-width: 25%;
      width: 100%;
    }
    .form-input {
      width: 100%;
    }
    .or {
      max-width: 30px;
      max-width: max-content;
      margin: auto 24px auto 0;
      text-align: center;
      transform: translate(0, 7px);
      @include phone {
        margin: 0;
        padding: 20px;
        transform: translate(0);
      }
    }
  }

  .email-field-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .section-instructions {
    + * {
      margin-top: 60px;
    }
  }
  .form-footer {
    text-align: left;
    @include phone {
      text-align: center;
    }
  }
  small {
    display: block;
    margin: 24px 0;
    width: 45%;
    line-height: 1.5;
    @include phone {
      width: 100%;
    }
    a {
      color: inherit;
    }
  }
}

.no-coverage-buttons {
  margin-top: 50px;
  .section-instructions {
    + * {
      margin-top: 15px;
    }
  }
}

.search-plan-input {
  position: relative;
  z-index: 1;
  label {
    z-index: 10;
  }
}

.search-results {
  position: absolute;
  width: 100%;
  max-width: $inputWidth;
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #979797;
  border-radius: 0 0 4px 4px;
  transform: translateY(-4px);
  z-index: 0;
  background: #fff;
  @include phone {
    max-width: 100%;
  }
  li {
    border-top: 1px solid #979797;
    &:first-child:not(.no-results) {
      padding-top: 4px;
      border-top: none;
    }
  }
  button {
    display: flex;
    flex: 1;
    width: 100%;
    border: none;
    background: none;
    padding: 6px 12px;
    text-align: left;
    @include phone {
      padding: 12px;
    }
    &:hover {
      background: #efefef;
    }
  }
}
.no-results {
  padding: 10px 12px 6px 12px;
}
