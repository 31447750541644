@import 'media-queries';
@import 'font-mixins';
@import 'vars';

// common
.wrapper {
  width: 100%;
  max-width: $dtcMaxWidth;
  margin: 0 auto;
  padding: 60px 15px;
  position: relative;
  @include phone {
    padding: 20px 15px;
  }
}

// header
.brandBar {
  z-index: 200;
  box-shadow: none !important;
  border-bottom: 1px solid #d8d8d8;
  background: #fff;
  position: fixed !important;
  left: 0;
  top: 0;
  right: 0;
  @include phone {
    position: relative !important;
  }
}

.mainHeader {
  justify-content: space-between !important;
  z-index: 1000;
  .headerLogo {
    z-index: 200;
    max-width: 65%;
  }
}

.hamMenuButton {
  width: 34px;
  height: 34px;
  display: none;
  background: none;
  margin: 0 0 0 auto;
  padding: 0;
  border: none;
  text-align: center;
  cursor: pointer;
  z-index: 100;
}

@media all and (max-width: 800px) {
  .hamMenuButton {
    display: block;
  }
}

.hamLine {
  display: block;
  width: 25px;
  height: 1px;
  background: #0e1d25;
  margin: 5px auto;
  transform-origin: center;
  transition: all 0.12s ease-in-out;
  &.navIsOpen {
    margin: 0 auto;
  }
}

.topLine {
  composes: hamLine;
  &.navIsOpen {
    transform: rotate(45deg);
  }
}

.bottomLine {
  composes: hamLine;
  &.navIsOpen {
    transform: rotate(-45deg);
  }
}

// mobile navigation
.mobileNav {
  display: flex;
  margin-left: auto;
  > * + * {
    margin-left: 30px;
  }
  &.navIsOpen {
    border-top: 1px solid #d8d8d8;
    display: flex;
    position: fixed;
    left: 0;
    top: 65px;
    right: 0;
    bottom: 0;
    z-index: 100;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #fff;
    padding: 20px;
    > * + * {
      margin-top: 30px;
      margin-left: 0;
    }
    a,
    button {
      line-height: 42px;
      font-weight: 700;
    }
  }
  a,
  button {
    background: none;
    border: none;
    padding: 0;
    @include graphik-regular;
    font-size: 16px;
    font-weight: 500;
    line-height: 34px;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    &:hover {
      color: $green;
    }
  }
}

@media all and (max-width: 800px) {
  .mobileNav {
    display: none;
  }
}

// hero
.hero {
  display: flex;
  flex-direction: column;
  min-height: 640px;
  min-height: 70vh;
  overflow: hidden;
  margin: 90px 0 0 0;
  padding: 0;
  max-width: 100%;
  flex: 1;
  align-items: stretch;
  position: relative;
  background-color: #f4f4f4;
  @include phone {
    background-color: #ffffff;
    flex-wrap: wrap;
    margin-top: 0;
    min-height: 450px;
  }
  @media (min-height: 1000px) {
    min-height: 640px;
  }

  &.vape {
    h1,
    p {
      @include desktop {
        color: #fff;
      }
    }
    .eligibilityNote {
      color: #fff;
      @include phone {
        color: #333;
      }
    }
    .flyerLink {
      color: #ffac00;
    }
  }
  &.other {
    @include desktop {
      background-color: #f7f2eb;
    }
  }
}

.heroImage {
  aspect-ratio: 1 / 0.45;
  width: 100%;
  height: 100%;
  position: absolute;
  padding-top: 40px;
  @include phone {
    position: static;
    margin: 30px 0;
    padding-top: 0;
  }
  img {
    object-position: top;
    left: 0;
    top: 0;
    right: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    z-index: 0;
    @include phone {
      position: static;
      min-height: 250px;
      object-fit: cover;
    }
  }
}

.heroRightImage {
  background: #edebed;
  width: 100%;
  height: 100%;
  position: absolute;
  padding-top: 40px;

  > div {
    max-width: $dtcMaxWidth;
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }
  picture {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include phone {
      width: 100%;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    margin: auto;
  }
  @include phone {
    position: static;
    margin: 30px 0;
  }
  &.other {
    background-color: transparent;
  }
}

.heroRightImage,
.heroImage {
  @include phone {
    .hero > & {
      display: none;
    }
  }
  @include desktop {
    .content & {
      display: none;
    }
  }
}

.content {
  z-index: 10;
  margin: auto;
  width: 100%;
  max-width: $dtcMaxWidth;
  display: flex;
  flex-direction: column;
  padding: 50px 15px;
  align-items: flex-start;
  @include phone {
    width: 100%;
    max-width: 100%;
    padding: 30px 0 15px;
    min-height: 250px;
    justify-content: space-between;
  }

  > * {
    max-width: 65%;
    + * {
      margin-top: 40px;
      @include phone {
        margin-top: 24px;
      }
    }
    @include phone {
      max-width: none;
    }
  }
  h1 {
    margin: 0;
    padding: 0;
    font-size: 48px;
    @include graphik-medium;
    font-weight: 700;
    color: #333333;
    max-width: 55%;
    @include phone {
      padding-right: 15px;
      padding-left: 15px;
    }
    @include phone {
      font-size: 38px;
      max-width: none;
    }
    @include small-phone {
      font-size: 30px;
      max-width: none;
    }
    > div {
      @include desktop {
        display: inline;
      }
      @include phone {
        min-height: 46px;
      }
    }
    br {
      @include phone {
        display: none;
      }
    }
  }
  p {
    max-width: 50%;
    font-size: 25px;
    line-height: 1.35;
    margin-bottom: 0;
    padding: 0;
    @include graphik-regular;
    color: #333333;
    @include phone {
      display: none;
      max-width: none;
    }
  }
}

.mainCtaButton {
  background: $green;
  border: 1px solid $green;
  cursor: pointer;
  border-radius: 16px;
  padding: 20px 30px;
  span {
    font-size: 20px;
    @include graphik-medium;
  }
  span {
    color: #fff;
  }
}

.mainCtaButtonDesktop {
  composes: mainCtaButton;
  @include phone {
    display: none;
  }
}

.mainCtaButtonMobile {
  composes: mainCtaButton;
  display: none;
  @include phone {
    display: block;
    margin: 24px auto 0 auto;
    padding: 16px 36px;
  }
}

.isDocked {
  @include phone {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    border-radius: 0;
    width: 100%;
    padding: 10px 30px;
    margin: 0;
    z-index: 1;
    background: #fff;
    border: 1px solid #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    span {
      padding: 14px 28px;
      background: $green;
      border-radius: 12px;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      display: inline-block;
    }
  }
}

.eligibilityNote {
  color: #696969;
  font-size: 15px;
  text-align: center;
  position: relative;
  &:before {
    @include desktop {
      content: '*';
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  @include desktop {
    max-width: 400px;
    text-align: left;
    line-height: 1.4;
    padding-left: 8px;
  }
}

@media all and (max-width: 800px) {
  .eligibilityNote {
    display: block;
    width: 100%;
    padding: 0 20px;
  }
}

.flyerWrapper {
  text-align: center;
  margin-top: 40px;
}

.flyerLink {
  @include graphik-medium;
  font-size: 16px;
  text-decoration: underline;
  color: #cc0000;
  &:hover {
    color: #da0000;
  }
  @include phone {
    border-top: none;
    margin-top: 0;
    padding-top: 20px;
  }
}

// green nicotine type switcher bar
.nicTypeSwitcher {
  z-index: 100;
  width: 100%;
  height: 60px;
  background: $green;
  display: flex;
  justify-content: center;
  @include phone {
    height: 55px;
  }
}

.nicTypeSwitcherWrapper {
  display: flex;
  flex: 1;
  justify-content: space-between;
  max-width: 2000px;
  @include phone {
    justify-content: center;
  }
  > span {
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    letter-spacing: 2px;
    padding-left: 28px;
    @include graphik-medium;
    @include phone {
      display: none;
    }
  }
}

.nicTypeSwitcherButtons {
  display: flex;
  @include phone {
    width: 100%;
  }
}

.nicTypeSwitcherButton {
  cursor: pointer;
  border: none;
  border-left: 3px solid #ccefe7;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  padding: 0 28px;
  @include phone {
    width: 100%;
    padding: 0 6px;
  }
  &:first-of-type {
    @include phone {
      border-left: none;
    }
  }
  span {
    padding-right: 4px;
    @include desktop {
      display: none;
    }
  }
  @include desktop {
    &:before {
      content: '';
      display: block;
      width: 34px;
      height: 34px;
      margin-right: 10px;
      background-position: center center;
      background-repeat: no-repeat;
      @include phone {
        width: 30px;
        height: 30px;
        background-size: contain;
        margin-right: 15px;
      }
    }
    &:after {
      content: '';
      display: block;
      width: 25px;
      height: 17px;
      margin-left: 10px;
      background: url(../media/right-chevron.svg) center center no-repeat;
      background-size: contain;
      transition: all 0.12s ease-in-out;
      transform: translate(0, 0);
    }
    &:hover:after {
      transform: translateX(4px);
    }
    &.cig {
      &:before {
        background-image: url(../media/icon-smoking.svg);
      }
    }
    &.vape {
      &:before {
        background-image: url(../media/icon-vape.svg);
      }
    }
    &.other {
      &:before {
        background-image: url(../media/icon-other.svg);
      }
    }
  }
}

// uniqueness list
.uniquenessList {
  .wrapper {
    @include phone {
      padding: 40px 15px;
    }
  }
  h2 {
    @include graphik-medium;
    font-weight: 700;
    margin: 0 0 1em 0;
    padding: 0;
    @include phone {
      font-size: 32px;
      font-weight: 600;
      transform: translateX(0);
      margin: 0;
    }
  }
}

.uniquenessListItems {
  display: flex;
  margin: 0;
  padding: 0 15px;
  @include phone {
    flex-direction: column;
  }
}

.uniquenessListItem {
  margin: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  @include phone {
    margin-top: 40px;
  }
  strong {
    height: 20%;
    display: block;
    padding: 0 10px;
    margin-top: 12px;
    @include graphik-medium;
    font-size: 16px;
    line-height: 1.3;
    @include phone {
      padding: 0 35px;
      margin-top: 22px;
      height: auto;
    }
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
  }
}

.roundCornerMask {
  padding: 10px;
  height: 80%;
  img {
    border-radius: 20px;
    overflow: hidden;
  }
  @include phone {
    padding: 0 35px;
  }
}

.containerDivider {
  padding: 10px 3%; // for older versions
  padding: 10px clamp(10px, 3%, 48px);
  @include phone {
    display: none;
  }
  span {
    width: 1px;
    height: calc(80% + 12px);
    display: block;
    background: #979797;
  }
}

// side by side sections
.sideBySide,
.coreLoop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coreLoop {
  .sectionCopy {
    display: flex;
    flex-direction: column;
    flex-basis: 48%;
  }
}

.coastToCoast {
  position: relative;
  .sectionImage {
    flex-basis: 100%;
    margin-top: clamp(0px, 5%, 50px);
  }
  .sectionCopy {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 50%;
    @include phone {
      position: static;
      max-width: none;
    }
    @include iPadProPortrait {
      top: 60px;
    }
    @include iPadPortrait {
      top: 60px;
    }
  }
  p {
    @include iPadProPortrait {
      display: none;
    }
    @include iPadPortrait {
      display: none;
    }
  }
}

.sideBySide,
.coastToCoast,
.coreLoop {
  composes: wrapper;
  max-width: $maxWidth;
  color: #333;
  @include phone {
    flex-direction: column;
    padding: 20px 15px;
  }
  @include iPadPortrait {
    padding-right: 30px;
    padding-left: 30px;
  }
  @include iPadProPortrait {
    padding-right: 30px;
    padding-left: 30px;
  }
  * {
    margin: 0;
    padding: 0;
  }
  h3 {
    font-size: 48px;
    line-height: 1.2;
    @include graphik-medium;
    font-weight: 700;
    @include phone {
      font-size: 32px;
      font-weight: 600;
    }
  }
  p {
    font-size: 25px;
    line-height: 1.5;
    @include graphik-regular;
    @include phone {
      text-align: center;
      font-size: 18px;
    }
  }
}

.sectionImage {
  flex-basis: 45%;
  @include phone {
    display: none;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.sectionImageVape {
  flex-basis: 45%;
  text-align: center;
  @include phone {
    display: none;
  }
  img {
    object-fit: contain;
  }
}

.sectionCopy {
  display: flex;
  flex-direction: column;
  flex-basis: 45%;
  > * + * {
    margin-top: 40px;
    @include phone {
      margin-top: 30px;
    }
  }
  hr {
    @include phone {
      display: none;
    }
  }
}

.sectionCopy .imageWrapper {
  display: none;
  width: 75%;
  margin-right: auto;
  margin-left: auto;
  @include phone {
    display: block;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}

.ctaButton {
  align-self: flex-start;
  background: #fff;
  border: 1px solid $green;
  color: $green;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  border-radius: 16px;
  padding: 16px 36px;
  &:hover {
    background: $green;
    color: #fff;
  }
  @include phone {
    margin: 24px auto 0 auto;
    padding: 16px 30px;
  }
}

// privacy policy
.privacyPolicy {
  padding-left: 30px;
  padding-right: 30px;
  @include phone {
    //margin-top: 30px;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 1230px;
    margin: auto;
    @include phone {
      padding-top: 30px;
      flex-direction: column;
    }
  }
  svg {
    margin-right: 20px;
    width: 23px;
    height: auto;
    flex-shrink: 0;
    @include phone {
      margin-right: 0;
    }
  }
  .privacyPolicyCopy {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: left;
    font-size: 15px;
    margin-left: 0;
    @include phone {
      padding: 30px 0;
    }
  }
}

.footerLegal {
  font-size: 18px;
  color: #333;
  h5 {
    font-weight: 600;
  }
  p {
    margin-top: 0;
    padding-top: 0;
    line-height: 1.35;
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    justify-content: space-between;
    align-items: flex-start;
    @include phone {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
  li {
    @include phone {
      margin-right: 15px;
    }
  }
  a {
    color: $greenDark;
  }
  hr {
    margin: 25px 0 25px 0;
  }
}

.footnotes {
  font-size: 12px;
  color: #272727;
  ol {
    margin: 0 0 1em 0;
    padding: 0 0 0 1em;
  }
}

.interactiveCTA {
  background: #f3f3f3;
  span {
    color: $green;
  }
  h3 {
    color: $green !important;
  }
  path {
    stroke: $green !important;
  }
  p + button {
    background-color: $greenDark !important;
    border-color: $greenDark !important;
    &:hover {
      background-color: $green !important;
      border-color: $green !important;
    }
    &:focus {
      box-shadow: 0 0 8px rgba($greenDark, 0.6) !important;
    }
  }
}

.programDisclaimer {
  background: #f3f3f3;
  color: $darkGray4;
  font-size: 16px;
  line-height: 1.35;
  text-align: left;
  display: block;
  > div {
    padding: 30px;
  }
  a {
    color: $green;
  }
  h3 {
    @include graphik-medium;
    font-size: 23px;
    line-height: 1;
    color: $textColor;
    margin: 0 0 10px 0;
    padding: 0;
    @include phone {
      font-size: 20px;
    }
  }
  button {
    color: darken($green, 2%);
    padding: 0;
    margin: 0;
    font-size: inherit;
    line-height: inherit;
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: contents;
  }
  @include phone {
    margin: 0;
    padding: 15px;
    font-size: 14px;
    text-align: center;
  }
}

.testimonialBanner {
  background: #daf0f9;
  padding-top: 30px;
  padding-bottom: 30px;
  @include phone {
    display: none;
  }
  blockquote {
    max-width: $dtcMaxWidth;
    margin: 0 auto;
    text-align: center;
    font-size: 25px;
    line-height: 1.8;
  }
  p {
    margin: 0;
    padding: 0 5vw;
  }
  cite {
    font-style: normal;
  }
}

// Streamlined landing page
.streamlinedContainer {
  padding-top: 32px;

  .eligibilityNote {
    margin-top: 20px;
    text-align: center;
    display: block;
    max-width: initial;
    &:before {
      position: initial;
    }
  }
}

.streamlinedImg {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-height: 50vh;
    @include phone {
      max-width: 100%;
      height: auto;
    }
  }
}
