.page-2-columns {
  text-align: left;
  .wrapper {
    display: flex;
    justify-content: space-between;
    @include phone {
      justify-content: center;
    }
  }
  .column {
    width: 100%;
    @include phone {
      width: 100%;
    }
    &:first-child {
      max-width: 520px;
      @include desktop {
        margin-right: 30px;
      }
    }
    &:last-child {
      max-width: 520px;
      display: flex;
      justify-content: center;
    }
    img {
      max-width: 100%;
      height: auto;
      align-self: flex-start;
    }
    &.showing-shipping-address {
      align-items: flex-start;
    }
  }
  .form-intro {
    margin: 0 0 1em 0;
    font-size: 18px;
    line-height: 1.4;
    color: $darkGray4;
    @include MN-Book;
    @include phone {
      font-size: 18px;
    }
  }
  .form-section {
    + .form-section {
      margin-top: 60px;
    }
  }
  h2,
  h3,
  h4 {
    @include G-Bold;
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 0.5em;
    display: inline-block;
  }
  h4 {
    font-size: 18px;
    @include MN-Book;
    margin-bottom: 1em;
    opacity: 0.5;
    line-height: 1.3;
  }
  .form-footer {
    text-align: left;
  }
  .privacy-notice {
    max-width: 620px;
    margin: 0;
  }
  .has-checkbox {
    @include phone {
      margin-top: 0;
    }
  }
}
