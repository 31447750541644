$blue: #1c3448;
$blueLight: #5c99cb;
$orange: #ff6e10;
$yellow: #ffd575;
$textOverGreen: #eeedeb;
$inputBorder: #979797;
$footerLinks: #6f6f6f;
$errorRed: #b22323;
$linkBlue: #00569b;
$green: #008767;
$greenDark: #006542;
$darkGray4: #666666;
$titleColor: #28231e;
$textColor: #3e3935;
$targetRed: #cc0000;
$targetRedDark: #da0000;
$footerHeight: 86px;
$headerHeight: 83px;
$maxWidth: 1184px;
$button-border-width: 2px;
$button-font-size: 19px;
$button-line-height: 1.44;
$button-padding-vertical: 4px;
$dtcMaxWidth: 1230px;
