@font-face {
  font-family: 'Graphik-Regular';
  src: url('../media/fonts/Graphik-Regular.otf') format('opentype'),
    url('../media/fonts/Graphik-Regular-Web.woff2') format('woff2'),
    url('../media/fonts/Graphik-Regular-Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik-Medium';
  src: url('../media/fonts/Graphik-Medium.otf') format('opentype'),
    url('../media/fonts/Graphik-Medium-Web.woff2') format('woff2'),
    url('../media/fonts/Graphik-Medium-Web.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
