$desktop: '(min-width: 991px)';
$mobile: '(max-width: 990px)';
$intermediate: '(min-width: 600px) and (max-width: 990px)';
$mobileOnly: '(max-width: 599px)';

@for $index from 1 through 10 {
  .push-top-#{$index} {
    margin-top: calc(8px * #{$index});
  }
  .push-bottom-#{$index} {
    margin-bottom: calc(8px * #{$index});
  }
}

.center-text {
  text-align: center;
}

.desktop-only {
  @media #{$mobile} {
    display: none;
  }
}

.mobile-only {
  @media #{$desktop} {
    display: none;
  }
}

.step {
  letter-spacing: normal;
  margin: auto;
  text-align: left;
  &-container {
    width: 100%;
  }
  &-copy {
    color: $darkGray4;
    @include MN-Book;
    line-height: 1.42;
    font-size: 22px;
    margin-bottom: 48px;
    @media #{$mobile} {
      font-size: 16px;
    }
    &.streamlined {
      color: $textColor;
      font-size: 18px;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 24px;
    }
    .page-eligibility & {
      margin-bottom: 24px;
    }
  }

  &-header {
    color: $titleColor;
    @include graphik-medium;
    font-weight: 600;
    line-height: 1.25;
    margin-top: 0;
    margin-bottom: 0.6em;
    font-size: 40px;
    @media #{$mobile} {
      font-size: 32px;
      letter-spacing: 0;
    }
    &.streamlined {
      text-align: center;
    }
  }

  &-content {
    &-next {
      padding-top: 3em;
      padding-bottom: 3em;
    }
  }

  .question {
    &-header {
      color: $textColor;
      @include G-Bold;
      line-height: 1.42;
      margin-top: 0;
      margin-bottom: 48px;
      font-size: 40px;
      @media #{$mobile} {
        font-size: 24px;
      }
    }

    &-field {
      @media #{$mobile} {
        width: 100%;
      }

      @media #{$desktop} {
        width: 300px;
      }
    }
  }

  .counters {
    margin-bottom: 4em;
  }
}

// for ios devices
@media not all and (min-resolution: 0.001dpcm) and (max-width: 990px) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .step-header {
      letter-spacing: -2px;
    }
  }
}

.selection-panel {
  display: flex;
  flex-flow: row wrap;

  &-option {
    border-radius: 4px;
    border-color: #979797;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    overflow: hidden;
    width: 100%;

    @media #{$mobile} {
      display: flex;
    }

    &-hero {
      border-width: 0;
      border-color: #979797;
      border-style: solid;
      border-bottom-width: 1px;
      height: auto;
      overflow: hidden;
      width: 100%;

      @media #{$mobile} {
        border-bottom-width: 0;
        border-right-width: 1px;
        height: 100px;
        width: auto;
      }

      &-image {
        display: block;
        height: auto;
        width: 100%;

        @media #{$mobile} {
          height: 100%;
          width: auto;
          display: inline-block;
        }
      }
    }

    &-title {
      display: flex;
      flex-direction: column;
      @include GR-Book;
      height: 4em;
      justify-content: center;
      line-height: 1.42;
      text-align: center;
      padding-left: 16px;
      padding-right: 16px;
      width: 100%;
      cursor: pointer;

      @media #{$mobile} {
        height: fit-content;
        margin: auto;
        padding: 16px;

        width: fit-content;

        &.own-width {
          flex: 1;
        }
      }

      & input[type='checkbox'],
      & input[type='radio'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: none;
        margin: 0;
      }
    }

    &-container {
      @media #{$desktop} {
        margin-bottom: 4%;
        width: 22%;
        margin-right: 4%;
        &:nth-child(4n + 4),
        &:last-child {
          margin-right: 0;
        }
      }

      @media #{$intermediate} {
        margin-bottom: 15px;
        width: 100%;

        .half-size-panel & {
          margin-left: 2%;
          margin-right: 2%;
          margin-bottom: 4%;
          width: 46%;
        }

        .quarter-size-panel & {
          margin-left: 2%;
          margin-right: 2%;
          margin-bottom: 4%;
          width: 20%;
        }
      }

      @media #{$mobileOnly} {
        margin-bottom: 15px;
        width: 100%;

        .half-size-panel & {
          margin-left: 3%;
          margin-right: 3%;
        }

        .quarter-size-panel & {
          margin-left: 2%;
          margin-right: 2%;
          margin-bottom: 4%;
          width: 46%;
        }
      }
    }
  }
  &-info {
    @include MN-Light;
    font-size: 18px;
    line-height: 1.33;
    color: $darkGray4;
    max-width: 22%;
    @media #{$mobileOnly} {
      max-width: 100%;
      margin-left: 3%;
      margin-right: 3%;
    }
  }
}

@media #{$desktop} {
  .selection-panel-option:hover {
    border-color: $green;
    background: $green;
    color: #fff;
  }
}

.option {
  &-container {
    @media #{$desktop} {
      width: 50%;
    }
    @media #{$mobile} {
      width: 100%;
    }
  }
}

.overlay {
  align-items: center;
  /* the oldest browser we support is IE11 which supports rgba */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(63, 57, 52, 0.87); /* Black w/ opacity */
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: auto;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  &-content {
    @include MN-Book;
    line-height: 1.44;
    opacity: 1;
    padding-left: 24px;
    padding-right: 24px;
    background-color: #ffffff;
    color: #504944;
    letter-spacing: -0.8px;
    @media #{$mobile} {
      width: 90%;
    }
    @media #{$desktop} {
      width: 70%;
    }
    &-header {
      color: $textColor;
      display: inline-block;
      @include G-Bold;
      line-height: 1.42;
      font-size: 32px;
      @media #{$mobile} {
        font-size: 24px;
      }
    }

    &-copy {
      color: $darkGray4;
      @include MN-Book;
      line-height: 1.42;
      margin-top: 24px;
      font-size: 24px;
      @media #{$mobile} {
        font-size: 16px;
      }
    }

    &-link {
      @include MN-Demi;
      color: $darkGray4;
    }

    button {
      margin-top: 24px;

      @media #{$desktop} {
        margin-bottom: 24px;
        margin-right: 24px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &-dismiss {
    background-color: $green;
    color: #ffffff;
    letter-spacing: 0.8px;
    text-align: center;
  }
}
