.email-confirmed {
  @include GR-Book;
  font-weight: 400;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-align: initial;
  display: flex;
  align-items: center;
  flex-direction: column;
  > .wrapper {
    display: flex;
    padding-right: 0;
  }
  h2 {
    font-size: 40px;
    @include G-Bold;
    @include phone {
      font-size: 28px;
    }
  }
  h3 {
    font-size: 30px;
    @include G-Bold;
    @include phone {
      font-size: 18px;
      margin: 0;
    }
  }
  h4 {
    @include G-Bold;
    font-size: 24px;
    color: $darkGray4;
  }
  p,
  ol {
    @include MN-Book;
    font-size: 24px;
    line-height: 1.42;
    color: $darkGray4;
    @include phone {
      font-size: 18px;
    }
  }
  ol {
    padding-left: 1em;
    strong {
      @include MN-Bold;
    }
  }
  .buttons {
    display: block;
  }
  .pivot-mobile {
    position: relative;
    padding-left: 30px;
    max-width: 45%;
    @include phone {
      display: none;
    }
    img {
      max-width: 100%;
    }
  }
  .pivot-text {
    display: inline-block;
    width: 100%;
    max-width: 610px;
    padding-right: 40px;
    color: #3e3934;
    text-align: left;
    @include phone {
      width: 100%;
      padding: 0;
    }
  }
  label {
    color: #28231e;
    font-size: 14px;
    margin-bottom: 8px;
    display: block;
  }
  .app-stores {
    display: flex;
    flex-direction: row;
    align-content: center;
    min-height: 110px;
    padding-bottom: 30px;
    @include phone {
      flex-direction: column;
      padding-bottom: 0;
      padding-top: 10px;
    }
    a {
      margin: 5px;
      margin-left: 0;
      margin-right: 20px;
      display: inline-block;
      min-width: 200px;
      + a {
        @include phone {
          margin-top: 30px;
        }
      }
      @include phone {
        max-width: 200px;
        width: 100%;
        height: auto;
        margin: auto;
      }
    }
    img {
      width: auto;
      height: 70px;
      @include phone {
        max-width: 200px;
        width: 100%;
        height: auto;
      }
    }
  }

  .input-message {
    width: 300px;
  }
}

.sms-input {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0 0 40px 0;
  .page-email-verified & {
    max-width: 450px;
  }
  .form-input {
    margin: 0 20px 0 0;
    flex: 1;
  }
  ::placeholder {
    color: rgba(103, 105, 105, 0.5);
  }
  button {
    height: 44px;
    padding-top: initial;
    padding-bottom: initial;
  }
}

.sms-input-title {
  font-size: 18px !important;
  min-width: 100%;
  margin: 20px 0 !important;
  font-weight: 600;
}
