.re-enrollment {
  text-align: left;

  .wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 100px;
  }

  .column {
    width: 100%;
    @include phone {
      width: 100%;
    }

    &:first-child {
      max-width: 450px;
    }

    &:last-child {
      max-width: 520px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      max-width: 100%;
    }
  }

  .step-header {
    font-size: 30px;
  }

  .form-intro {
    margin: 0 0 1em 0;
    font-size: 18px;
    line-height: 1.4;
    color: #676969;
    @include MN-Book;
    @include phone {
      font-size: 18px;
    }
  }
}
