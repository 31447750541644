.page-registration-complete {
  > .wrapper {
    display: flex;
    padding-right: 0;
    padding-bottom: 0;
    @include phone {
      padding-top: 30px;
      padding-right: 15px;
      padding-bottom: 30px;
      flex-direction: column-reverse;
      align-items: center;
      .section-title {
        margin-bottom: 30px;
      }
    }
    @include small-phone {
      .section-title {
        margin-bottom: 15px;
        font-size: 24px;
      }
    }
  }
  h2 {
    @include G-Bold;
    font-size: 24px;
    color: #676969;
    @include phone {
      font-size: 26px;
    }
  }
  h3 {
    font-size: 30px;
    @include G-Bold;
    @include phone {
      font-size: 18px;
      margin: 0;
    }
  }
  h4 {
    @include G-Bold;
    font-size: 24px;
    color: $darkGray4;
  }
  p,
  ol,
  ul {
    @include MN-Book;
    font-size: 20px;
    line-height: 1.42;
    color: $darkGray4;
    @include phone {
      font-size: 18px;
    }
  }
  ol,
  ul {
    padding-left: 0;
    list-style: none;
    strong {
      @include MN-Bold;
    }
  }
  li + li {
    margin-top: 1em;
  }
  .buttons {
    display: block;
  }
  .pivot-mobile {
    position: relative;
    padding-left: 30px;
    max-width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include phone {
      display: none;
    }
    img {
      max-width: 100%;
      max-height: 550px;
    }
  }
  .pivot-verified {
    position: relative;
    padding-right: 30px;
    padding-bottom: 30px;
    min-width: 50%;
    max-width: 70%;

    img {
      height: 100%;
      max-width: 100%;
    }

    @include phone {
      padding-right: 15px;
      padding-left: 15px;
      max-width: 100%;

      img {
        height: unset;
        max-width: 100%;
      }
    }
  }
  .pivot-text {
    display: inline-block;
    width: 100%;
    max-width: 610px;
    padding-right: 40px;
    color: #3e3934;
    text-align: left;
    @include phone {
      width: unset;
      padding: 0;
    }
  }
  label {
    color: #28231e;
    font-size: 14px;
    margin-bottom: 8px;
    display: block;
  }
  .app-stores {
    display: flex;
    flex-direction: row;
    align-content: center;
    min-height: 110px;
    padding-bottom: 30px;
    @include phone {
      flex-direction: column;
      padding-bottom: 0;
      padding-top: 10px;
    }

    a {
      margin: 5px;
      margin-left: 0;
      margin-right: 20px;
      display: inline-block;
      min-width: 200px;

      + a {
        @include phone {
          margin-top: 30px;
        }
      }

      @include phone {
        max-width: 200px;
        width: 100%;
        height: auto;
        margin: auto;
      }
    }

    img {
      width: auto;
      height: 70px;
      @include phone {
        max-width: 200px;
        width: 100%;
        height: auto;
      }
    }
  }
  .input-message {
    width: 300px;
  }
  .support-message {
    font-size: 18px;
    word-wrap: break-word;
    @include phone {
      font-size: 14px;
    }
  }
}

.open-pivot-app {
  @include GR-Book;
  text-align: center;
  margin-bottom: 15px;
  border: 0;
  border-radius: 8px;
  font-size: 18px;
  &.wide {
    width: 100%;
    display: block;
  }
}
