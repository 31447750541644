.next-frame-button {
  @extend .cs-button;
  .form-footer & {
    width: 480px;
    @include phone {
      width: 100%;
      font-size: 20px;
    }
    &.full-width {
      width: 100%;
    }
  }
}

.cancel-button {
  color: $textColor;
  border-color: $textColor;
  background-color: #fff;
}

@keyframes slideUpOnEntry {
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.slide-up-on-entry {
  will-change: transform, opacity;
  transform: translate(0, 80px);
  opacity: 0;
  animation: slideUpOnEntry 400ms ease-out forwards;
  animation-delay: 120ms;
  .page-access-code & {
    transform: translate(0, 0);
    opacity: 1;
    animation: none;
  }
}

.section-header {
  width: 100%;
  max-width: $maxWidth;
  margin: 75px auto 35px auto;
  padding: 0 30px;
  text-align: center;
  z-index: 10;
  &.signup-section-header {
    text-align: left;
  }
  @include phone {
    padding: 0 15px;
    margin: 25px auto;
  }
  h1 {
    font-size: 32px;
    color: $darkGray4;
    @include GR-Book;
    font-weight: normal;
    margin: 0;
    padding: 0;
    line-height: 1;
    @include phone {
      font-size: 24px;
      width: 100%;
    }
    + .subtitle {
      margin-top: 1em;
    }
  }
  .subtitle {
    margin: 0;
    text-transform: none;
    text-align: center;
    width: 100%;
  }
}

.wrapper {
  width: 100%;
  max-width: $maxWidth;
  margin: 0 auto;
  padding: 78px 30px 100px 30px;
  position: relative;
  @include phone {
    padding: 25px 15px 60px 15px;
  }
}

.section-title {
  text-align: left;
  font-size: 36px;
  color: $textColor;
  margin: 0 0 55px 0;
  padding-top: 0;
  @include G-Bold;
  @include phone {
    font-size: 32px;
  }
}

.section-subtitle {
  text-align: left;
  @include G-Bold;
  padding-top: 0;
}

.section-instructions {
  @include MN-Book;
  color: $darkGray4;
  font-size: 24px;
  text-align: left;
  margin-bottom: 1em;
  line-height: 1.45;
  margin-top: 0;
  padding-top: 0;
  a {
    color: $green;
  }
  @include phone {
    font-size: 18px;
  }
}

ul.section-instructions {
  padding-left: 1em;
  margin-left: 0;
  line-height: 1.6;
}

.smaller-instructions {
  font-size: 18px;
}

.orange {
  color: $orange;
}

.green {
  color: $green;
}

.resend-confirmation-notice {
  margin-top: 85px;
}

.form-footer {
  + .privacy-notice {
    margin-top: 60px;
  }
}

.privacy-notice {
  color: #666666;
  font-size: 12px;
  a {
    color: $green;
  }
  @include phone {
    margin-top: 0;
  }
}

.side-by-side-select {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  @include phone {
    flex-wrap: wrap;
  }
  .question {
    text-align: left;
    font-size: 18px;
    @include GR-Book;
    @include phone {
      text-align: left;
      width: 100%;
      margin-bottom: 8px;
    }
  }
  .input-container {
    width: 100%;
    max-width: 320px;
    @include phone {
      width: 100%;
      max-width: 100%;
    }
  }
  &.is-blurred-true {
    select {
      // Enable this for different select color after selection
      // color: rgba($orange, 1);
    }
  }
}

.questions {
  opacity: 1;
  z-index: 1;
  padding-bottom: 100px;
  transition: all 0.66s ease-out;
  &.is-hidden {
    opacity: 0;
  }
  .form-footer {
    margin-top: 100px;
  }
}

.results {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  padding: 100px 6%;
  opacity: 0;
  z-index: -1;
  transition: all 0.66s ease-out;
  &.is-visible {
    opacity: 1;
    z-index: 2;
    background: #f9f9f9;
  }
}

.paynow-dropdowns {
  .side-by-side-select {
    .input-container {
      max-width: 36%;
      flex-shrink: 0;
      @include phone {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
