@mixin graphik-regular {
  font-family: Graphik-Regular, sans-serif;
  font-weight: 400;
}

@mixin graphik-medium {
  font-family: Graphik-Medium, sans-serif;
  font-weight: 500;
}

@mixin G-Bold {
  font-family: Graphik-Medium, sans-serif;
  font-weight: 500;
}

@mixin GR-Light {
  font-family: Graphik-Regular, sans-serif;
  font-weight: 300;
}

@mixin GR-Book {
  font-family: Graphik-Regular, sans-serif;
  font-weight: 400;
}

@mixin GR-Medium {
  font-family: Graphik-Regular, sans-serif;
  font-weight: 400;
}

@mixin GR-Bold {
  font-family: Graphik-Medium, sans-serif;
  font-weight: 500;
}

@mixin MN-Light {
  font-family: Graphik-Regular, sans-serif;
  font-weight: 300;
}

@mixin MN-Book {
  font-family: Graphik-Regular, sans-serif;
  font-weight: 400;
}

@mixin MN-Medium {
  font-family: Graphik-Regular, sans-serif;
  font-weight: 400;
}

@mixin MN-Demi {
  font-family: Graphik-Regular, sans-serif;
  font-weight: 400;
}

@mixin MN-Bold {
  font-family: Graphik-Medium, sans-serif;
  font-weight: 400;
}
