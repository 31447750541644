.page-share-pivot {
  .form-input {
    width: 25%;
    margin: 0 24px 0 0;
    @include phone {
      width: 100%;
    }
  }
  .input-container + * {
    margin-top: 20px;
  }

  .section-instructions {
    + .input-container {
      margin-top: 80px;
    }
  }
  .form-footer {
    text-align: left;
    @include phone {
      text-align: center;
    }
  }
}
