@import 'vars';
@import 'font-mixins';
@import 'media-queries';

.brand-bar {
  @include phone {
    padding: 15px;
  }
  .wrapper {
    justify-content: flex-start;
  }
}

.fixed-brand-bar {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 100;
  background: rgba(#fff, 0);
  box-shadow: none;
  @include phone {
    padding-top: 15px;
    padding-bottom: 15px;
    max-height: 90px;
  }
  &.is-active {
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    .wrapper {
      *:not(button) {
        opacity: 1;
      }
    }
  }
  .wrapper {
    @include phone {
      justify-content: flex-start;
    }
    *:not(button) {
      opacity: 0;
    }
  }
  .brand-bar-copy {
    color: #fff;
    @include phone {
      min-width: 100%;
      display: block;
      line-height: 1.3;
      margin-top: 15px;
    }
  }
  button {
    padding: 12px 28px;
    font-size: 20px;
    border-radius: 16px;
    margin-left: auto;
    @include phone {
      border-width: 2px;
      padding: 6px 10px;
      font-size: 16px;
      border-radius: 12px;
    }
  }
  .login-button {
    margin-right: 0;
    + button {
      margin-left: 16px;
      @include phone {
        margin-left: 6px;
      }
    }
  }
}

.hero {
  text-align: center;
  padding-top: 60px;
  @include phone {
    padding-top: 30px;
  }
  h1 {
    text-align: center;
    color: $green;
    @include MN-Bold;
    font-size: 40px;
    margin: 0 0 20px 0;
    padding: 0;
    @include phone {
      font-size: 24px;
      padding-left: 30px;
      padding-right: 30px;
      line-height: 1.4;
    }
  }
  img {
    max-width: $dtcMaxWidth;
    height: auto;
    max-height: 52vh;
    margin: 15px auto;
    @include phone {
      max-width: 100%;
    }
  }
  .pricing {
    margin-bottom: 20px;
    span {
      font-size: 40px;
      color: #444;
      margin: 0 15px;
      @include phone {
        font-size: 24px;
      }
    }
  }
  .pricing-before {
    @include MN-Book;
    &:after {
      background: #444;
    }
  }
  .pricing-terms {
    @include MN-Book;
    font-size: 18px;
    display: block;
    line-height: 1.5;
    @include phone {
      font-size: 12px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

.icons {
  background-color: #f8f8f8;
  padding-top: 40px;
  padding-bottom: 40px;
  @include phone {
    padding-bottom: 0;
  }
  h2 {
    text-align: center;
    color: #444444;
    @include MN-Bold;
    font-size: 32px;
    margin: 0 0 32px 0;
    padding: 0;
    @include phone {
      font-size: 24px;
    }
  }
  .icons-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: $dtcMaxWidth;
    margin: 0 auto;
    @include phone {
      flex-wrap: wrap;
    }
  }
  .icon {
    text-align: center;
    margin: 0 auto;
    min-width: 20%;
    padding: 0 30px;
    @include phone {
      flex-basis: 50%;
      flex-shrink: 0;
      margin: 0 0 45px 0;
      padding: 0;
    }
    img {
      display: block;
      margin: auto auto 22px auto;
    }
    span {
      font-size: 18px;
      line-height: 1.3;
      color: #444444;
      @include MN-Light;
      @include phone {
        font-size: 12px;
      }
    }
  }
}

.program-disclaimer {
  color: $darkGray4;
  font-size: 16px;
  line-height: 1.35;
  text-align: center;
  display: block;
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
  max-width: $dtcMaxWidth;
  padding-left: 15px;
  padding-right: 15px;
  a {
    color: $green;
  }
  @include phone {
    margin: -30px 0 0 0;
    padding: 15px;
  }
}

.green-title-bar {
  background: $green;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  @include phone {
    padding: 20px;
  }
  h2 {
    color: #fff;
    text-align: center;
    @include MN-Bold;
    font-size: 40px;
    margin: 0;
    padding: 0;
    @include phone {
      font-size: 24px;
    }
  }
}

.side-by-sides {
  .side-by-side {
    background-color: #f4f4f4;
    &:nth-child(odd) {
      background-color: #fff;
      .image-and-copy-wrapper {
        flex-direction: row;
        background-position: left center;
      }
    }
  }
  .image-and-copy-wrapper {
    padding: 100px 30px;
    max-width: $dtcMaxWidth;
    margin: auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    @include phone {
      padding: 30px;
      flex-direction: column !important;
      background-image: none !important;
    }
  }
  a[name] {
    position: relative;
    top: -200px;
    display: block;
  }
  .image,
  .copy {
    flex-basis: 45%;
    flex-shrink: 0;
  }
  .image {
    @include phone {
      display: block;
      max-width: 100%;
      height: auto;
      margin-bottom: 15px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .copy {
    h3 {
      color: #444444;
      @include MN-Bold;
      font-size: 32px;
      line-height: 1.5;
      margin: 0;
      padding: 0;
      @include phone {
        font-size: 24px;
      }
      span {
        display: block;
        color: darken($green, 2%);
        font-size: 20px;
        text-transform: uppercase;
      }
    }
    ul {
      margin: 0;
      padding: 1em 0 0 1.5em;
    }
    li,
    p {
      font-size: 18px;
      line-height: 1.8;
      color: $darkGray4;
      margin-bottom: 0;
      @include phone {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.section-subnav {
  background: linear-gradient($green 33%, #f4f4f4 33%, #f4f4f4 100%);
  padding-bottom: 30px;
  @include phone {
    display: none;
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    max-width: 1130px;
    padding-top: 0;
    padding-bottom: 0;
  }
  a {
    position: relative;
    max-width: 200px;
    flex-basis: 200px;
    height: 160px;
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
    color: $green;
    text-decoration: none;
    overflow: visible;
    transform-origin: center;
    transform: scale(1);
    transition: all 0.2s ease-in;
    > * {
      position: relative;
      z-index: 1;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      transform: scale(1.065);
    }
  }
  svg {
    margin-bottom: 15px;
  }
}

.testimonials {
  background-color: $green;
  padding-top: 60px;
  padding-bottom: 85px;
  @include phone {
    padding-bottom: 50px;
  }
  h2 {
    text-align: center;
    color: #fff;
    @include MN-Bold;
    font-size: 40px;
    margin: 0 0 50px 0;
    padding: 0 45px;
    @include phone {
      font-size: 24px;
      line-height: 27px;
      margin-bottom: 10px;
    }
  }
  .testimonials-wrapper {
    display: flex;
    justify-content: center;
    max-width: $dtcMaxWidth;
    margin: auto;
    @include phone {
      flex-direction: column;
    }
  }
  .testimonial {
    background-color: #fff;
    padding: 70px 55px;
    margin: 25px;
    flex-basis: 33.3%;
    font-size: 20px;
    color: $darkGray4;
    line-height: 2;
    @include phone {
      font-size: 12px;
      line-height: 20px;
      padding: 30px;
      margin: 20px 45px;
    }
    p {
      margin: 0;
      padding: 0;
    }
    cite {
      display: block;
      margin-top: 50px;
      font-style: normal;
      &:before {
        content: '- ';
      }
      @include phone {
        margin-top: 25px;
      }
    }
  }
}

.privacy-policy {
  background: $green;
  color: #fff;
  text-align: center;
  @include phone {
    font-size: 14px;
    text-align: left;
  }
  p {
    font-size: 16px;
    line-height: 1.4;
    margin: 0 auto;
    max-width: $dtcMaxWidth;
    padding: 35px 15px;
    @include phone {
      padding: 15px 30px;
    }
  }
}

.interactive-cta {
  h2 {
    margin: 0;
    padding: 0;
    text-align: center;
    @include graphik-medium;
    font-size: 35px;
    color: $textColor;
  }
  .wrapper {
    max-width: $dtcMaxWidth;
  }
  .questions-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
    @include phone {
      flex-direction: column;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .question {
    flex-basis: calc(20% - 20px);
    margin: 0 10px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    @media (max-width: 1160px) {
      flex-basis: calc(33% - 20px);
      margin-bottom: 20px;
    }
    * {
      cursor: pointer;
    }
    &.is-active {
      .card {
        @include phone {
          height: auto;
        }
      }
      .card-inner {
        @include desktop {
          transform: rotateY(180deg);
        }
      }
      .card-front {
        &:before {
          display: none;
        }
      }
      .card-back {
        &:before {
          @include desktop {
            transform: scale(1.05);
          }
        }
      }
    }
    + .question {
      @include phone {
        margin-top: 15px;
      }
    }
  }
  .card {
    display: block;
    width: 100%;
    height: 360px;
    position: relative;
    perspective: 500px;
    will-change: transform;
    transition: transform 0.25s ease-in-out;
    @include phone {
      height: 90px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
      border-radius: 8px;
      overflow: hidden;
    }
  }
  .card-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.26s;
    transform-style: preserve-3d;
    @include phone {
      position: relative;
    }
  }
  .card-front,
  .card-back {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    background: #fff;
    text-align: center;
    backface-visibility: hidden;
    @include phone {
      padding: 20px 30px;
      position: static;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      transition: all 0.12s ease-in-out;
      @include phone {
        display: none;
      }
    }
  }
  .card-front {
    &:hover {
      &:before {
        @include desktop {
          transform: scale(1.05);
        }
      }
    }
    &:focus-within {
      &:before {
        outline: 2px solid $green;
      }
    }
  }
  .card-back {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 15px 15px 20px 15px;
    @include desktop {
      transform: rotateY(180deg);
    }
    @include phone {
      padding-top: 10px;
    }
    h3 {
      color: $targetRedDark;
      @include graphik-medium;
      font-size: 18px;
      margin: auto auto 15px auto;
      padding: 0;
      text-align: center;
      @include phone {
        border: 0;
        clip: rect(1px, 1px, 1px, 1px);
        -webkit-clip-path: inset(50%);
        clip-path: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute !important;
        width: 1px;
        word-wrap: normal !important;
      }
    }
    p {
      font-size: 14px;
      line-height: 1.6;
      width: 100%;
      margin: 0;
      padding: 0;
      @include phone {
        text-align: left;
        margin-top: 0;
        padding-top: 0;
      }
    }
    button {
      background: $targetRed;
      border-color: $targetRed;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
      border-radius: 16px;
      font-size: 20px;
      margin: auto auto 0 auto;
      padding: 13px max(5px, 16px);
      @include phone {
        margin-top: 25px;
      }
      &:hover {
        background: $targetRedDark;
        border-color: $targetRedDark;
      }
      &:focus {
        outline: none;
        box-shadow: 0 0 8px rgba($targetRedDark, 0.6);
      }
    }
  }
  .img-container {
    height: 68px;
    margin: 40px auto 30px auto;
    @include phone {
      margin: 0;
      height: 30px;
    }
  }
  svg {
    display: block;
    margin: 0 auto;
    width: auto;
    max-height: 100%;
  }
  path {
    stroke: $targetRed;
  }
  .show-answer-btn {
    @include graphik-regular;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 2;
    background: none;
    text-align: center;
    border: none;
    font-size: 23px;
    color: $targetRed;
    line-height: 30px;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 30px 15px;
    &:hover {
      color: $targetRedDark;
      path {
        stroke: $targetRedDark;
      }
    }
    &:focus {
      outline: none;
    }
    span {
      @include phone {
        flex: 1;
        max-width: 80%;
        margin: 0 auto 0 0;
        line-height: 1.4;
      }
    }
    @include phone {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      text-align: left;
      font-size: 18px;
      padding: 0;
    }
  }
}

.interactive-cta-modal {
  .interactive-cta {
    background: #f4f4f4;
  }
  .close-modal-button {
    z-index: 100;
  }
  .inner-modal {
    max-width: $dtcMaxWidth;
    padding: 0;
  }
  section {
    width: 100%;
  }
  .interactive-cta {
    .wrapper {
      padding-top: 50px;
      padding-bottom: 30px;
    }
  }
}
