.steps-progress-bar {
  @include phone {
    display: none;
  }
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  ul {
    display: flex;
  }
  li {
    flex-basis: 33.3333%;
    flex-shrink: 0;
    background: #f3f3f3;
    color: #3e3935;
    @include graphik-medium;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    letter-spacing: 3px;
    padding: 10px;
  }

  span {
    padding: 0 25px;
  }

  .is-completed {
    span {
      background-image: url('../media/progressbar-checkmark.svg');
      background-position: right center;
      background-repeat: no-repeat;
      background-size: auto 90%;
    }
  }

  &.step-1-active {
    li:first-child {
      background-image: url('../media/progress-bar-gray-white.svg');
      background-position: right center;
      background-repeat: no-repeat;
    }
  }
  &.step-2-active {
    li:first-child {
      background-image: url('../media/progress-bar-white-stroke-arrow.svg');
      background-position: right center;
      background-repeat: no-repeat;
      + li {
        background-image: url('../media/progress-bar-gray-white.svg');
        background-position: 100.5% center;
        background-repeat: no-repeat;
      }
    }
  }
  &.step-3-active {
    li:first-child {
      background-image: url('../media/progress-bar-white-stroke-arrow.svg');
      background-position: right center;
      background-repeat: no-repeat;
      + li {
        background-image: url('../media/progress-bar-white-stroke-arrow.svg');
        background-position: right center;
        background-repeat: no-repeat;
      }
    }
  }

  .is-active {
    background: #e7e7e7;
  }
}
