@function rem($size) {
  @return $size / 16px * 1rem;
}

@function units($size) {
  @return ($size * $u) / 16px * 1rem;
}

@mixin clearfix() {
  &::after {
    display: block;
    content: '';
    clear: both;
  }
}
