$leftWidth: 180px;

.account-nav {
  margin: auto 0 auto auto;
  a {
    text-decoration: none;
    color: inherit;
    margin-left: 32px;
    &:hover {
      color: $green;
    }
    &.selected {
      font-weight: bold;
    }
  }
}

.profile {
  padding-top: 0;
  max-width: 950px;

  .form-section {
    max-width: 780px;
  }
  .forgot-password-link {
    margin-top: 0;
    position: relative;
    top: -18px;
    text-align: right;
    width: 100%;
    max-width: calc(50% - 15px);
    @include phone {
      max-width: 100%;
    }
  }
  .form-input,
  .password-strength-container {
    margin-left: 0;
    max-width: calc(50% - 15px);
    @include phone {
      max-width: 100%;
    }
  }
  .password-strength-container {
    .form-input {
      width: 100%;
      max-width: unset;
    }
  }
  .form-footer {
    @include phone {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    button {
      margin-top: 0;
    }
  }
}

.profile-items {
  margin-top: 40px;
}

.profile-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  @include phone {
    flex-wrap: wrap;
  }
  + * {
    margin-top: 20px;
  }
}

.item-definition {
  flex-basis: $leftWidth;
  flex-shrink: 0;
  @include phone {
    min-width: 100%;
  }
}

.item-value {
  color: $green;
}

.address-format {
  white-space: pre;
  line-height: 1.65;
}

.address-instructions {
  margin-left: $leftWidth;
  margin-top: 20px;
  @include phone {
    margin-left: 0;
  }
  a {
    color: $linkBlue;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.signout-button {
  color: $linkBlue;
  background: none;
  border: none;
  @include GR-Book;
  font-size: 14px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  margin-left: 20px;
  &:hover {
    text-decoration: underline;
  }
}

.profile-form-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  .next-frame-button {
    width: auto;
  }
}

.forgot-password-link {
  display: inline-block;
  font-size: 12px;
  text-decoration: none;
  color: #28231e;
  margin-top: 20px;
  background: none;
  border: none;
  cursor: pointer;
  &:hover {
    color: $orange;
  }
}

.request-payment-message {
  background: $green;
  color: #fff;
  font-size: 22px;
  @include GR-Medium;
  padding: 40px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 50px;
  @include phone {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px 15px;
    font-size: 16px;
  }
  h3 {
    font-size: 1em;
  }
  .checkbox-container {
    input[type='checkbox'] + label {
      font-size: 18px;
      color: #ffffff;
      background-image: url(../media/icon-checkbox-checked-white.svg); //for preloading purposes
      background: url(../media/icon-checkbox-unchecked-white.svg) left top
        no-repeat;
      background-size: auto 18px;
    }
    input[type='checkbox']:checked + label {
      background-image: url(../media/icon-checkbox-checked-white.svg);
      background-size: auto 18px;
    }
  }
}

.request-payment-message-copy {
  span {
    display: block;
    line-height: 1.5;
  }
}

.request-payment-message-img {
  margin-right: 20px;
  @include phone {
    margin: 0 auto 15px auto;
  }
}

.order-now-button {
  margin-top: 25px;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  &:hover {
    background: #fff;
    color: $green;
  }
}

.download-instructions-after-payment {
  background: $green;
  color: #fff;
  font-size: 18px;
  @include GR-Medium;
  padding: 40px;
  margin-bottom: 50px;
  @include phone {
    padding: 15px;
  }
  h1 {
    color: #fff;
    font-size: 32px;
    margin-bottom: 1rem;
  }
  .app-stores {
    margin-top: 40px;
    a {
      @include phone {
        display: block;
      }
    }
    a + a {
      margin-left: 30px;
      @include phone {
        margin-left: 0;
        margin-top: 15px;
      }
    }
  }
}

.subscription-container {
  margin-bottom: 80px;
  .subscription-list-description {
    margin-top: 24px;
  }
}

.subscription-button {
  border-radius: 8px;
  margin-top: 16px;
}

.subscription-items {
  list-style: none;
  margin: 0;
  padding: 0 0 0 1em;
  li {
    margin: 16px 0 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
  }
  svg {
    margin-right: 24px;
  }
  b {
    display: block;
    font-size: 16px;
    margin: 4px 0;
  }
  span {
    display: block;
    font-size: 14px;
    margin: 4px 0;
  }
}

.page-subscription-renewal {
  .wrapper {
    align-items: center;
    justify-content: flex-end !important;
    flex-direction: row-reverse;
    .column {
      &:first-child {
        padding-left: 40px;
        max-width: none;
        @include phone {
          padding-left: 0;
        }
      }
    }
  }
}

.page-reorder-sensor {
  img {
    max-width: 100%;
    margin-bottom: 40px;
  }
  h1 {
    margin-bottom: 24px;
  }
  .request-confirmation {
    font-size: 18px;
  }
  .address-profile-item {
    margin-bottom: 32px;
  }
  .item-definition {
    display: none;
  }
  .item-value {
    font-size: 16px;
    color: #767676;
    @include MN-Demi;
  }
  button + button {
    margin-left: 8px;
  }
}
