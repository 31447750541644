.mini-form-container {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 15px;
  flex-direction: column;
  max-width: 630px;
  text-align: center;
  form {
    width: 100%;
    position: relative;
    z-index: 10;
    flex-shrink: 0;
    background: #fff;
    border: 1px solid rgba(#979797, 0.35);
    border-radius: 8px;
    padding: 35px;
    @include phone {
      width: 100%;
      padding: 15px;
    }
    p {
      text-align: center;
      margin-top: 0;
      padding-top: 0;
    }
  }
}

.form-intro {
  margin-bottom: 45px;
}
