.cs-button {
  @include GR-Medium;
  font-size: 20px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  padding: 16px 40px;
  border: solid 1px $green;
  border-radius: 22px;
  cursor: pointer;
  transition: all 0.12s ease-in-out;
  white-space: nowrap;
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  background: $green;
  max-width: 100%;
  @include phone {
    padding: 16px 46px;
  }
  &:hover {
    color: #fff;
    background: $greenDark;
    border-color: $greenDark;
  }
  &.is-sending {
    background: #006542;
    border-color: #006542;
  }
  &.is-disabled,
  &[disabled] {
    pointer-events: none;
    background: #666666;
    border-color: #666666;
    color: #fff;
  }
  .spinner {
    left: 0;
    right: auto;
    transform: scale(0.75) translate(5px, -35%);
  }
}

.cs-button-smaller {
  font-size: 16px;
  padding: 12px 32px;
  .spinner {
    left: 0;
    right: auto;
    transform: scale(0.5) translate(-5px, -55%);
  }
}

.cs-button-borderless {
  border: solid 1px transparent;
  background: transparent;
  color: $green;
}

.cs-button-outlined {
  border: 1px solid $green;
  background: transparent;
  color: $green;
}

.cs-button-pill {
  border-radius: 100px;
}

.cs-button-primary {
  background: $green;
  color: #fff;
  &:hover {
    background: $green;
  }
}

.cancel-button {
  color: $textColor;
  border-color: $textColor;
  background: #ffffff;
}

.simple-link-button {
  @include GR-Medium;
  font-size: 18px;
  line-height: 1;
  color: $green;
  border: none;
  background: none;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: $green;
    text-decoration: underline;
    background: none;
  }
}

$desktop: '(min-width: 800px)';

.button {
  @include GR-Medium;
  font-size: $button-font-size;
  font-stretch: normal;
  font-style: normal;
  line-height: $button-line-height;
  padding-bottom: $button-padding-vertical;
  padding-top: $button-padding-vertical;
  text-align: center;
  border-radius: 62px;
  letter-spacing: -0.8px;
  width: 100%;
  @media #{$desktop} {
    border-radius: 31px;
    letter-spacing: -0.4px;
    width: 175px;
  }
  &-primary-action-enabled {
    background-color: $green;
    border: solid $button-border-width $green;
    color: #ffffff;
  }
  &-primary-action-disabled {
    background-color: #eeedeb;
    border: solid $button-border-width #eeedeb;
    color: #ffffff;
  }
  &-secondary-action {
    background-color: #ffffff;
    border: solid $button-border-width $green;
    color: $green;
  }
}
